<template>
  <div>
    <v-container class="container not-printable" fluid>
      <SearchStudent style="display: inline-block" @clicked="onSearch" />
    </v-container>
    <v-container class="container not-printable" fluid>
      <StudentsTable :students="students" />
    </v-container>
  </div>
</template>

<script>
import SearchStudent from "./../components/students/SearchStudent.vue";
import StudentsTable from "./../components/students/StudentsTable.vue";

export default {
  components: {
    SearchStudent,
    StudentsTable,
  },
  data() {
    return {
      students: [],
      showTable: false,
    };
  },
  methods: {
    onSearch(value) {
      console.log(value);
      this.students = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>

23228020174

<template>
  <div>
    <v-form>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-autocomplete
            v-model="provinceId"
            :items="$store.state.provinces"
            item-value="idProvince"
            item-text="provinceName"
            item-color="accent"
            color="accent"
            label="المحافظة"
            outlined
            :disabled="!editable"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field v-model="district" color="accent" label="المنطقة" outlined :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field v-model="avenue" color="accent" label="المحلة" outlined :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field v-model="streetNumber" color="accent" label="رقم الزقاق" outlined :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field v-model="houseNumber" color="accent" label="رقم الدار" outlined :disabled="!editable"></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="mb-5 mt-5" />

      <v-btn color="error" depressed large @click="editable = !editable">
        <span>{{ editable ? "الغاء التحديث" : "تحديث المعلومات" }}</span>
      </v-btn>

      <v-btn v-if="editable" class="mr-5" depressed color="success" large @click.stop="saveAddress">
        <span class="secondary--text">حفظ المعلومات</span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editable: false,

      provinceId: "",
      provinces: [],

      district: "",
      avenue: "",
      houseNumber: "",
      streetNumber: "",
      idAddress: "",
      studentSection: "",
    };
  },

  created() {
    this.fetchStudents();
  },

  methods: {
    fetchStudents() {
      let self = this;
      console.log(self.studentId);
      this.$http
        .get(`${this.$store.state.apiUrlRegistration}/studentAddress/${this.studentId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((response) => {
          if (response.data !== null) {
            this.idAddress = response.data.idAddress;
            this.provinceId = response.data.provinceId;
            this.district = response.data.district;
            this.avenue = response.data.avenue;
            this.houseNumber = response.data.houseNumber;
            this.streetNumber = response.data.streetNumber;
          }
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 404
              ? this.$toasted.error("لم يتم ادخال بيانات السكن للطالب", {
                  duration: 3000,
                  position: "top-center",
                })
              : this.$toasted.error("حدث خطأ في البيانات", {
                  duration: 3000,
                  position: "top-center",
                });
            console.log("Error status:", error.response.status);
            console.log("Error data:", error.response.data);
          } else {
            console.log("Error:", error.message);
          }
        });
    },

    saveAddress() {
      let self = this;
      const data = {
        idAddress: this.idAddress,
        provinceId: this.provinceId,
        district: this.district,
        avenue: this.avenue,
        houseNumber: this.houseNumber,
        streetNumber: this.streetNumber,
        studentId: this.studentId,
      };

      if (self.idAddress == null) {
        this.$http
          .post(`${this.$store.state.apiUrlRegistration}/addAddress`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });

            this.editable = false;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            console.error(error);
          });
      } else {
        this.$http
          .put(`${this.$store.state.apiUrlRegistration}/address/${self.idAddress}`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            this.$toasted.success("تم تعديل المعلومات", {
              duration: 3000,
              position: "top-center",
            });

            this.editable = false;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div>
      <div style="margin-top: 30px"></div>
      <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
        <h3 style="color: #ffffff">الصفحة الرئيسية</h3>
      </v-card>
    </div>

    <v-row>
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="whiteColor">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الاقسام</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="whiteColor">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-down</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الطلبة</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="whiteColor">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-down</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الاوامر الادارية</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students }}
          </h4>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <SectionChart />
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <CategoryChart />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionChart from "./../components/charts/SectionChart.vue";
import CategoryChart from "./../components/charts/CategoryChart.vue";
export default {
  components: {
    CategoryChart,
    SectionChart,
  },
  name: "Home",
  data() {
    return {
      overlay: false,
      students: 0,
      orders: 0,
    };
  },
};
</script>

<template>
  <div>
    <v-dialog v-model="reportsDialog" persistent transition="slide-y-transition" max-width="750px">
      <v-card color="secondary" class="shadow-1 rounded-1">
        <v-toolbar color="primary" dark class="shadow-1 rounded-1">
          <h4>فرز الاوامر الادارية</h4>
          <v-spacer />
          <v-btn icon color="secondary" @click="reportsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-form ref="reportsForm" v-model="reportsForm" @submit.prevent="createReports">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-select :items="$store.state.levels" item-value="value" item-text="text" color="primary" outlined item-color="primary" label="مرحلة الامر" name="orderLevel" clearable></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-autocomplete
                  name="reportOrderTitleId"
                  :items="$store.state.orderTitles"
                  item-text="title"
                  item-value="idOrderTitle"
                  item-color="primary"
                  color="primary"
                  outlined
                  label="عنوان الامر"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field name="reportOrderNumber" color="primary" outlined label="رقم الامر" clearable></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-select
                  v-model="reportOrderYear"
                  name="reportOrderYear"
                  :items="$store.state.yearStudies"
                  item-value="idYearStudy"
                  item-text="year"
                  item-color="primary"
                  color="primary"
                  outlined
                  label="سنة الامر"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-menu ref="orderDateMenuModel" v-model="orderDateMenuModel" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="reportOrderDate" name="reportOrderDate" outlined color="primary" label="تاريخ الامر" v-bind="attrs" clearable v-on="on"></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="reportOrderDate"
                    :active-picker.sync="orderDateActivePicker"
                    :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveOrderDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-divider />

            <v-row class="mt-5 mb-5">
              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-autocomplete
                  :items="$store.state.sections"
                  item-value="idSection"
                  item-text="sectionName"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="القسم"
                  name="sectionId"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-select
                  :items="$store.state.yearStudies"
                  item-value="idYearStudy"
                  item-text="year"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="سنة القبول"
                  name="registerYearId"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-select
                  :items="$store.state.studentStatus"
                  item-value="idStudentStatus"
                  item-text="statusName"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="حالة الطالب"
                  name="studentStatusId"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-select outlined color="primary" label="الجنس" :items="$store.state.genders" item-text="text" item-value="value" item-color="primary" name="gender"></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-select :items="$store.state.studyTypes" item-value="value" item-text="text" color="primary" outlined item-color="primary" label="نوع الدراسة" name="studyType" clearable></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-select :items="$store.state.levels" item-value="value" item-text="text" color="primary" outlined item-color="primary" label="مرحلة الطالب" name="studentLevel" clearable></v-select>
              </v-col>

              <!-- <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-select
                  v-model="studentCategory"
                  :items="selectCategory"
                  item-value="idStudyCategory"
                  item-text="categoryName"
                  color="primary"
                  outlined
                  label="الاختصاص"
                  return-object
                  item-color="primary"
                  @change="categorySelected"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-select
                  v-model="studySubCategoryId"
                  :items="selectSubCategory"
                  item-value="idStudySubCategory"
                  item-text="subCategoryName"
                  color="primary"
                  outlined
                  label="الفرع"
                  item-color="primary"
                  :disabled="!isSelectCategory"
                ></v-select>
              </v-col> -->
            </v-row>

            <v-divider />
            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="headers.selected" label="تحديد الكل" color="primary" @change="headers.map((h) => (h.selected = true))"></v-checkbox>
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="mt-5 mb-5">
              <v-col v-for="item in headers" :key="item.value" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-checkbox v-model="item.selected" :label="item.text" color="primary"></v-checkbox>
              </v-col>
            </v-row>

            <v-btn type="submit" color="success" block depressed>
              <span class="secondary--text">تطبيق الفرز</span>
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-card id="tableWrapper" class="shadow-navbar rounded-1">
      <v-data-table id="orderTable" :headers="selectedHeader" :items="orders" :search="search" :item-class="row_classess">
        <template #top>
          <v-toolbar color="primary" class="shadow-navbar rounded-1 mb-8">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث في الاوامر الادارية..." single-line hide-details dark color="secondary"></v-text-field>

            <v-spacer></v-spacer>

            <v-tooltip bottom color="secondary" transition="slide-y-transition">
              <template #activator="{ on, attrs }">
                <v-btn light color="secondary" depressed class="ml-3 primary--text" v-bind="attrs" icon v-on="on" @click="reportsDialog = true">
                  <v-icon>mdi-sort</v-icon>
                </v-btn>
              </template>
              <span class="primary--text">انشاء تقرير</span>
            </v-tooltip>

            <v-tooltip v-if="orders.length > 0" bottom color="secondary" transition="slide-y-transition">
              <template #activator="{ on, attrs }">
                <v-btn icon color="secondary" class="primary--text ml-3" v-bind="attrs" v-on="on" @click="tableExport('#orderTable table', 'orders', 'reports.xls')">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span class="primary--text">تصدير الجدول</span>
            </v-tooltip>

            <h4 class="mr-10 secondary--text">عدد الاوامر الادارية: {{ ordersCount }}</h4>
          </v-toolbar>
        </template>

        <template #[`item.exitCauses`]="{ item }">
          <span>{{ item.student.ExitCauses.length > 0 ? item.student.ExitCauses[0].exitCausesTitle : "لا يوجد" }}</span>
        </template>

        <template #[`item.age`]="{ item }">
          <span>{{ new Date().getFullYear() - item.student.dob.split("-")[0] }}</span>
        </template>

        <template #[`item.examNumber`]="{ item }">
          <span>{{ item.student.studentSchool.examNumber }}</span>
        </template>

        <template #[`item.student.studentGraduation`]="{ item }">
          <span>{{ item.student.studentGraduation !== null ? item.student.studentGraduation : "لا يوجد" }}</span>
        </template>

        <template #[`item.personalPhone`]="{ item }">
          <div v-for="responsables in item.student.studentResponsables" :key="responsables.idStudentResponsible">
            <span v-if="responsables.responsibleName === 'رقم شخصي'">{{ responsables.responsiblePhone }}</span>
            <span v-else>لا يوجد</span>
          </div>
        </template>

        <template v-slot:[`item.index`]="{ index }">
          <p style="padding: 0 3px">{{ index + 1 }}</p>
        </template>
        <template v-slot:[`item.level`]="{ item }">
          <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
        </template>

        <template v-slot:[`item.orderLevel`]="{ item }">
          <p style="padding: 0 3px">{{ item.orderLevel | setLevel }}</p>
        </template>

        <template v-slot:[`item.section`]="{ item }">
          <p style="padding: 0 3px">{{ $store.state.sections.find((sec) => sec.idSection == item.sectionId).sectionName }}</p>
        </template>

        <template v-slot:[`item.studyType`]="{ item }">
          <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
        </template>

        <template #[`item.parentPhone`]="{ item }">
          <div v-for="responsables in item.student.studentResponsables" :key="responsables.idStudentResponsible">
            <span v-if="responsables.responsibleName === 'رقم الاب'">{{ responsables.responsiblePhone }}</span>
            <span v-else>لا يوجد</span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <a id="dlink" class="d-none" href="">Download</a>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      ordersCount: 0,
      rules: [(v) => !!v || "لا يمكن ترك الحقل فارغ"],
      loading: true,
      orderDateActivePicker: null,
      orderDateMenuModel: false,
      reportsDialog: false,
      orders: [],
      students: [],
      search: "",
      orderYearText: "",
      reportsForm: false,

      // reports
      reportOrderTitleId: "",
      reportOrderNumber: "",
      reportOrderYear: "",
      reportOrderDate: null,
      reportStudentId: "",

      selectOrderTitle: [],
      selectOrderYear: [],

      reportSelectSections: [],
      reportSelectRegisterYear: [],
      reportSelectStatus: [],
      reportSelectAcceptedType: [],
      studentGender: "",
      reportSelectStudyType: [
        {
          text: "صباحي",
          value: "1",
        },
        {
          text: "مسائي",
          value: "0",
        },
      ],
      // reports

      headers: [
        {
          text: "التسلسل",
          sortable: false,
          align: "right",
          value: "index",
          selected: true,
        },
        {
          text: "الرقم الجامعي",
          sortable: true,
          align: "right",
          value: "collegeNumber",
          width: 200,
          selected: true,
        },
        {
          text: "اسم الطالب",
          sortable: true,
          align: "right",
          value: "studentName",
          width: 200,
          selected: true,
        },
        {
          text: "القسم",
          value: "section",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "المرحلة",
          value: "level",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "نوع الدراسة",
          value: "studyType",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "عنوان الامر",
          value: "title",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "رقم الامر",
          value: "orderNumber",
          sortable: false,
          align: "start",
          selected: true,
          width: 100,
        },
        {
          text: "تاريخ الامر",
          value: "orderDateFormat",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "مرحلة الامر",
          value: "orderLevel",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "سنة الامر",
          value: "year",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "نص الامر",
          value: "orderDescription",
          sortable: false,
          align: "start",
          selected: true,
          width: 500,
        },
        {
          text: "حالة الطالب",
          value: "statusName",
          width: 200,
          sortable: false,
          selected: true,
        },

        {
          text: "الاسم الانجليزي",
          value: "englishName",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "بريد الطالب",
          value: "mail",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الجنس",
          value: "gender",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "تاريخ الميلاد",
          value: "dob",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الشعبة",
          value: "class",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الجنسية",
          value: "nationality",
          width: 200,
          sortable: false,
          selected: false,
        },
      ],

      cloneHeaders: null,

      orderTitle: "",
      orderNumber: "",
      orderLevel: "",
      orderYear: null,
      orderDate: null,
      orderStatus: "",
      orderCreatedBy: "",
      orderDatePicker: null,
      orderDateMenu: false,

      orderSelectTitle: [],
      orderSelectYear: [],
      orderSelectStatus: [],
      orderSelectLevel: [
        {
          text: "المرحلة الاولى",
          value: 1,
        },
        {
          text: "المرحلة الثانية",
          value: 2,
        },
        {
          text: "المرحلة الثالثة",
          value: 3,
        },
        {
          text: "المرحلة الرابعة",
          value: 4,
        },
        {
          text: "المرحلة الخامسة",
          value: 5,
        },
      ],
      selectGender: [
        {
          text: "ذكر",
          value: "0",
        },
        {
          text: "انثى",
          value: "1",
        },
      ],

      selectCategory: [],
      selectSubCategory: [],
      studentCategory: [],
      studentSubCategory: "",
      isSelectCategory: false,
      studySubCategoryId: "",
    };
  },

  computed: {
    selectedHeader() {
      return this.headers.filter((h) => h.selected);
    },
  },

  watch: {
    orderDateMenuModel(val) {
      val && setTimeout(() => (this.orderDateActivePicker = "YEAR"));
    },

    orderDateMenu(val) {
      val && setTimeout(() => (this.orderDatePicker = "YEAR"));
    },
  },

  mounted() {
    this.cloneHeaders = _.cloneDeep(this.headers);
  },

  methods: {
    categorySelected(category) {
      this.selectSubCategory = category.StudySubCategory;
      this.isSelectCategory = true;
    },

    saveOrderDate(date) {
      this.$refs.orderDateMenuModel.save(date);
    },

    row_classess(item) {
      // const userSection = this.$auth.user.sectionId;
      // const orderSection = item.user.sectionId;
      // if (userSection !== orderSection) {
      //   return "disabled_row";
      // }
      return false;
    },

    convertIntToText(int) {
      let str = {};
      if (int === 1) {
        str = {
          text: "المرحلة الاولى",
          value: 1,
        };
      } else if (int === 2) {
        str = {
          text: "المرحلة الثانية",
          value: 2,
        };
      } else if (int === 3) {
        str = {
          text: "المرحلة الثالثة",
          value: 3,
        };
      } else if (int === 4) {
        str = {
          text: "المرحلة الرابعة",
          value: 4,
        };
      } else if (int === 5) {
        str = {
          text: "المرحلة الخامسة",
          value: 5,
        };
      } else {
        str = {
          text: "متخرج",
          value: 10,
        };
      }

      return str;
    },

    createReports(event) {
      let self = this;
      if (this.$refs.reportsForm.validate()) {
        const data = Object.fromEntries(new FormData(event.target));
        this.loading = true;

        let searchQuery = {};

        if (data.reportOrderTitleId) {
          searchQuery.orderTitleId = data.reportOrderTitleId;
        }

        if (data.reportOrderNumber) {
          searchQuery.orderNumber = data.reportOrderNumber;
        }

        if (data.reportOrderYear) {
          searchQuery.orderYear = this.reportOrderYear;
        }

        if (data.reportOrderDate) {
          searchQuery.orderDate = data.reportOrderDate;
        }

        if (data.sectionId) {
          searchQuery.sectionId = data.sectionId;
        }

        if (data.studentStatusId) {
          searchQuery.studentStatusId = data.studentStatusId;
        }

        if (data.studentLevel) {
          searchQuery.studentLevel = data.studentLevel;
        }

        if (data.studyType) {
          searchQuery.studyType = data.studyType;
        }

        if (data.registerYearId) {
          searchQuery.registerYearId = data.registerYearId;
        }

        if (data.gender) {
          searchQuery.gender = data.gender;
        }

        if (data.orderLevel) {
          searchQuery.orderLevel = data.orderLevel;
        }

        if (
          !data.orderLevel &&
          !data.sectionId &&
          !data.studyType &&
          !data.orderTitleId &&
          !data.gender &&
          !data.studentLevel &&
          !data.registerYearId &&
          !data.orderNumber &&
          !data.studentStatusId &&
          !data.reportOrderYear &&
          !data.reportOrderDate
        ) {
          this.$toasteded.error("الرجاء اختيار احد المحددات");
          self.overlay = false;
          return;
        }

        self.$http
          .get(`${self.$store.state.apiUrlRegistration}/filterAdministrativeOrders?search=${JSON.stringify(searchQuery)}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            console.log(res.data);
            self.orders = res.data;
            self.ordersCount = res.data.length;
            this.reportsDialog = false;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.$toasteded.error("حدث خطأ في جلب البيانات");
          });
      }
    },

    resetFilterAndTableSettinges() {
      this.headers = this.cloneHeaders;
    },

    tableExport(table, name, filename) {
      var uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
          });
        };
      if (!table.nodeType) table = document.querySelector(table);
      var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };

      document.getElementById("dlink").href = uri + base64(format(template, ctx));
      document.getElementById("dlink").download = filename;
      document.getElementById("dlink").click();
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else if (level == null) {
        return "لايوجد";
      } else {
        return "متخرج";
      }
    },
    setStudyType: function (type) {
      if (type == 1) {
        return "صباحي";
      } else if (type == 2) {
        return "مسائي";
      } else {
        return "لايوجد";
      }
    },
  },
};
</script>

<style lang="scss">
.disabled_row {
  // pointer-events: none !important;

  * {
    color: #898989 !important;
  }
}
</style>

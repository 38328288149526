<template>
  <div>
    <v-form @submit.prevent="saveStudentResponsible">
      <v-row>
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-select v-model="responsibleName" color="primary" :items="phoneTypes" label="نوع الهاتف" outlined item-color="primary"></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
          <v-text-field v-model="responsiblePhone" color="primary" label="رقم ولي الامر" outlined></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn color="success" depressed large block @click="saveStudentResponsible">
            <span class="primary--text">حفظ المعلومات</span>
          </v-btn>
        </v-col>

        <v-col cols="12" class="mb-5">
          <v-card color="primary" elevation="0">
            <v-list v-for="responsible in responsibleInfo" :key="responsible.idStudentResponsible" nav dense outlined>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="grey lighten-1"> mdi-phone </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="responsible.responsibleName"></v-list-item-title>

                  <v-list-item-subtitle v-text="responsible.responsiblePhone"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="deletePhoneNumber(responsible)">
                    <v-icon color="grey lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="nationalNumber" color="primary" label="رقم الجنسية" outlined></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="motherName" color="primary" label="اسم الام" outlined></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-text-field v-model="religion" color="primary" label="الديانة" outlined></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn color="success" depressed large block @click="saveStudentNational">
            <span class="primary--text">حفظ المعلومات</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
          <v-text-field v-model="nationalityCertificateNumber" color="primary" label="رقم شهادة الجنسية" outlined></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn color="success" depressed large block @click="saveStudentNationalCertificate">
            <span class="primary--text">حفظ المعلومات</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-btn depressed color="accent" large @click="$store.state.studentStep = 3">
      <span class="info--text">تخطي</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disableNationalInfoArea: true,
      disableResponsibleInfoArea: true,
      disableNationalityCertificateArea: true,
      responsibleName: "",
      responsiblePhone: "",
      nationalityCertificateNumber: "",
      nationalNumber: "",
      religion: "مسلم",
      motherName: "",
      phoneTypes: ["رقم الام", "رقم الاب", "رقم الاقارب", "رقم شخصي"],
      responsibleInfo: [],
      idNationalityCertificate: "",
      idNationalInfo: "",
    };
  },
  created() {
    console.log(this.$store.state.newStudentId);
  },

  computed: {
    studentId() {
      return this.$store.getters.getLastStudentRegistered;
    },
  },

  methods: {
    saveStudentResponsible() {
      let self = this;

      this.$http
        .post(
          `${self.$store.state.apiUrlRegistration}/addStudentResponsible`,
          {
            responsibleName: this.responsibleName,
            responsiblePhone: this.responsiblePhone,
            studentId: self.$store.state.newStudentId,
          },
          { headers: { Authorization: `Bearer ${self.$store.state.user}` } }
        )
        .then((res) => {
          self.responsibleInfo.push(res.data);
          this.$toasted.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في حفظ البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    saveStudentNational() {
      let self = this;

      this.$http
        .post(
          `${self.$store.state.apiUrlRegistration}/addNationalInfo`,
          {
            nationalNumber: this.nationalNumber,
            motherName: this.motherName,
            religion: this.religion,
            studentId: self.$store.state.newStudentId,
          },
          { headers: { Authorization: `Bearer ${self.$store.state.user}` } }
        )
        .then((res) => {
          this.$toasted.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في حفظ البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    saveStudentNationalCertificate() {
      let self = this;

      this.$http
        .post(
          `${self.$store.state.apiUrlRegistration}/addNationalityCertificate`,
          {
            nationalityNumber: this.nationalityCertificateNumber,
            idNationalityCertificate: this.idNationalityCertificate,
            studentId: self.$store.state.newStudentId,
          },
          { headers: { Authorization: `Bearer ${self.$store.state.user}` } }
        )
        .then((res) => {
          this.$toasted.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في حفظ البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    deletePhoneNumber(item) {
      let self = this;
      try {
        this.$toasted.show("جاري حذف الرقم...", {
          duration: 3000,
          position: "top-center",
        });

        console.log(item);

        this.$http
          .delete(`${self.$store.state.apiUrlRegistration}/studentResponsible/${item.idStudentResponsible}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            this.$toasted.success("تم حذف الرقم بنجاح", {
              duration: 3000,
              position: "top-center",
            });
          })
          .catch((e) => {});

        this.responsibleInfo.splice(item, 1);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

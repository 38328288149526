<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="shadow-navbar rounded-1">
      <v-toolbar color="primary" dark class="shadow-navbar mb-5 mt-5 rounded-1">
        <h4>تقارير الطلبة</h4>
        <v-spacer />
        <h4>عدد الطلاب: {{ studentsCount }}</h4>
      </v-toolbar>
      <div class="pa-10">
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-text-field color="primary" v-model="studentName" outlined item-color="primary" label="اسم الطالب" name="studentName" clearable dense hide-details></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="$store.state.sections"
              item-value="idSection"
              item-text="sectionName"
              v-model="sectionId"
              color="primary"
              outlined
              item-color="primary"
              label="القسم"
              name="sectionId"
              clearable
              dense
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="$store.state.yearStudies"
              item-value="idYearStudy"
              item-text="year"
              v-model="yearStudyId"
              color="primary"
              outlined
              item-color="primary"
              label="سنة القبول"
              name="registerYearId"
              clearable
              dense
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="levels"
              v-model="level"
              item-value="value"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="المرحلة"
              name="studentLevel"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="$store.state.acceptedTypes"
              v-model="acceptedTypeId"
              item-value="idAcceptedType"
              item-text="acceptedName"
              color="primary"
              outlined
              item-color="primary"
              label="نوع القبول"
              name="acceptedTypeId"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="studyTypes"
              v-model="studyType"
              item-value="value"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="نوع الدراسة"
              name="studyType"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="$store.state.studentStatus"
              item-value="idStudentStatus"
              item-text="statusName"
              v-model="studentStatusId"
              color="primary"
              outlined
              item-color="primary"
              label="الحالة"
              name="studentStatusId"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              :items="genders"
              v-model="gender"
              item-value="text"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="الجنس"
              name="gender"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-btn block large color="background" dark depressed @click="searchStudents" class="mt-5"> بحث </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studentsCount: 0,
      overlay: false,

      studentName: "",
      level: "",
      studentStatusId: "",
      acceptedTypeId: "",
      gender: "",
      graduationDate: "",
      studyType: "",
      yearStudyId: "",
      sectionId: "",

      genders: [{ text: "ذكر" }, { text: "انثى" }],
      studyTypes: [
        { text: "صباحي", value: 1 },
        { text: "مسائي", value: 2 },
      ],
      levels: [
        { text: "المرحلة الاولى", value: 1 },
        { text: "المرحلة الثانية", value: 2 },
        { text: "المرحلة الثالثة", value: 3 },
        { text: "المرحلة الرابعة", value: 4 },
        { text: "المرحلة الخامسة", value: 5 },
      ],
    };
  },
  methods: {
    searchStudents() {
      let self = this;
      self.overlay = true;

      let searchQuery = {};
      if (self.studentName) {
        searchQuery.studentName = self.studentName;
      }

      if (self.sectionId) {
        searchQuery.sectionId = self.sectionId;
      }

      if (self.studyType) {
        searchQuery.studyType = self.studyType;
      }

      if (self.acceptedTypeId) {
        searchQuery.acceptedTypeId = self.acceptedTypeId;
      }

      if (self.gender) {
        searchQuery.gender = self.gender;
      }

      if (self.level) {
        searchQuery.level = self.level;
      }

      if (self.graduationDate) {
        searchQuery.graduationDate = self.graduationDate;
      }

      if (self.yearStudyId) {
        searchQuery.yearStudyId = self.yearStudyId;
      }

      if (self.studentStatusId) {
        searchQuery.studentStatusId = self.studentStatusId;
      }

      if (!self.studentName && !self.sectionId && !self.studyType && !self.acceptedTypeId && !self.gender && !self.level && !self.graduationDate && !self.yearStudyId && !self.studentStatusId) {
        this.$toasted.error("الرجاء اختيار احد المحددات");
        self.overlay = false;
        return;
      }

      console.log(searchQuery);

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/studentSearchRegister?search=${JSON.stringify(searchQuery)}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          self.overlay = false;
          self.studentsCount = res.data.length;
          this.$emit("clicked", res.data);
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

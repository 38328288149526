<template>
  <div>
    <v-dialog v-model="adminOrderDialog" persistent transition="slide-y-transition" max-width="750px">
      <v-card color="secondary" class="shadow-1 rounded-1">
        <v-toolbar color="primary" dark class="shadow-1 rounded-1">
          <h4 v-if="!isLevelUpApi && !isGraduationApi">تعميم امر اداري</h4>
          <h4 v-if="isLevelUpApi">ترحيل الطلاب</h4>
          <h4 v-if="isGraduationApi">تخريج الطلاب</h4>

          <v-spacer></v-spacer>

          <v-btn color="secondary" icon @click="adminOrderDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-10">
          <v-form ref="adminOrderForm" v-model="adminOrderForm" lazy-validation>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-autocomplete
                  v-model="orderTitleId"
                  :items="$store.state.orderTitles"
                  item-value="idOrderTitle"
                  item-text="title"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="عنوان الامر الاداري"
                  :rules="rules"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field v-model="orderNumber" color="primary" outlined label="رقم الامر الاداري" :rules="rules"> </v-text-field>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                :md="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :lg="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :xl="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
              >
                <v-autocomplete
                  v-model="orderLevel"
                  :items="$store.state.levelsWithGraduation"
                  item-value="value"
                  item-text="text"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="مرحلة الامر الاداري"
                  :rules="rules"
                >
                </v-autocomplete>
              </v-col>

              <v-col v-if="isLevelUpApi || isGraduationApi" cols="12" xs="12" sm="12" :md="isLevelUpApi ? '6' : '6'" :lg="isLevelUpApi ? '6' : '6'" :xl="isLevelUpApi ? '6' : '6'">
                <v-autocomplete
                  v-model="orderNewLevel"
                  :items="$store.state.levelsWithGraduation"
                  item-value="value"
                  item-text="text"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="مرحلة الطالب"
                  :rules="rules"
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                :md="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :lg="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :xl="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
              >
                <v-autocomplete
                  v-model="orderYear"
                  :items="$store.state.yearStudies"
                  item-value="idYearStudy"
                  item-text="year"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="سنة الامر الاداري"
                  :rules="rules"
                >
                </v-autocomplete>
              </v-col>

              <v-col v-if="isGraduationApi" cols="12" xs="12" sm="12" :md="isGraduationApi ? '6' : '4'" :lg="isGraduationApi ? '6' : '4'" :xl="isGraduationApi ? '6' : '4'">
                <v-autocomplete
                  v-model="graduationDate"
                  :items="$store.state.yearStudies"
                  item-value="idYearStudy"
                  item-text="year"
                  color="primary"
                  outlined
                  item-color="primary"
                  label="سنة التخرج"
                  :rules="rules"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                :md="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :lg="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
                :xl="isLevelUpApi ? '6' : '4' || isGraduationApi ? '6' : '4'"
              >
                <v-menu ref="orderDateMenu" v-model="orderDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="orderDate" label="تاريخ الامر الاداري" readonly v-bind="attrs" outlined color="primary" :rules="rules" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="orderDate"
                    header-color="primary"
                    :active-picker.sync="orderDatePicker"
                    :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    color="primary"
                    @change="saveOrderDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="!isLevelUpApi && !isGraduationApi" cols="12" sm="12" :md="orderStatus === 5 ? '6' : '12'" :lg="orderStatus === 5 ? '6' : '12'" :xl="orderStatus === 5 ? '6' : '12'">
                <v-select
                  v-model="orderStatus"
                  :items="$store.state.studentStatus"
                  color="primary"
                  outlined
                  item-text="statusName"
                  item-value="idStudentStatus"
                  item-color="primary"
                  label="حالة الطالب"
                  :rules="rules"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea v-model="orderDescription" color="primary" outlined label="نص الامر الاداري" :rules="rules"> </v-textarea>
              </v-col>
            </v-row>

            <v-file-input v-model="attachment" color="primary" outlined label="اختر الملف..." @change="fileSaved"></v-file-input>

            <v-btn block color="success" depressed large :disabled="!adminOrderForm" type="submit" class="primary--text" @click.prevent="createAdminOrders"> حفظ المعلومات </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-btn icon color="secondary" depressed class="primary--text ml-3" @click="adminOrderDialog = true">
      <v-icon v-if="isLevelUpApi">mdi-export</v-icon>
      <v-icon v-if="isGraduationApi">mdi-logout</v-icon>
      <v-icon v-if="!isGraduationApi && !isLevelUpApi">mdi-sitemap</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isLevelUpApi: Boolean,
    isGraduationApi: Boolean,
  },
  data() {
    return {
      adminOrderDialog: false,
      orderStatus: "",
      graduationDate: "",
      orderDescription: "",
      orderLevel: "",
      orderTitleId: "",
      orderNumber: "",
      attachment: null,
      orderDatePicker: null,
      orderDateMenu: false,
      orderDate: null,
      adminOrderForm: false,
      orderYear: null,
      orderNewLevel: "",
      rules: [(v) => !!v || "لا يمكن ترك الحقل فارغ"],
      uploadedImage: "",
    };
  },
  methods: {
    fileSaved() {
      let self = this;
      const file = new FormData();
      file.append("attachment", this.attachment);

      this.$http
        .post(`${self.$store.state.apiUrlRegistration}/uploadStudentImageImage`, file, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((path) => {
          self.uploadedImage = path.data.imagePath;
        })
        .catch((error) => {
          this.$toasted.error("حدث خطأ في حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
          console.error(error);
        });
    },
    createAdminOrders() {
      let self = this;

      console.log(self.$store.state.studentSelectedList);
      if (self.isLevelUpApi && self.isFileSelected) {
        let studentIds = self.$store.state.studentSelectedList.map((student) => {
          return { studentId: student.idStudent, studentLevel: student.level, studentClass: student.class, yearStudyId: student.yearStudyId };
        });

        let data = {
          orderTitleId: self.orderTitleId,
          orderNumber: self.orderNumber,
          orderDescription: self.orderDescription,
          orderYear: self.orderYear,
          orderLevel: self.orderLevel,
          studentIds: studentIds,
          newStudentLevel: self.orderNewLevel,
          orderDate: self.orderDate,
          imagePath: self.uploadedImage,
          createdBy: 1,
          titleType: 1,
        };
        console.log(data);

        self.$http
          .post(`${self.$store.state.apiUrlRegistration}/addMultiAdministrativeOrder`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
          });
      } else if (self.isGraduationApi && self.isFileSelected) {
        let studentIds = self.$store.state.studentSelectedList.map((student) => {
          return { studentId: student.idStudent, studentLevel: student.level, studentClass: student.class, yearStudyId: student.yearStudyId };
        });

        let data = {
          orderTitleId: self.orderTitleId,
          orderNumber: self.orderNumber,
          orderDescription: self.orderDescription,
          orderYear: self.orderYear,
          orderLevel: self.orderLevel,
          studentIds: studentIds,
          newStudentLevel: self.orderNewLevel,
          orderDate: self.orderDate,
          graduationDate: self.graduationDate,
          imagePath: self.uploadedImage,
          createdBy: 1,
          titleType: 2,
        };
        console.log(data);

        self.$http
          .post(`${self.$store.state.apiUrlRegistration}/addMultiAdministrativeOrder`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
          });
      } else {
        let studentIds = self.$store.state.studentSelectedList.map((student) => {
          return { studentId: student.idStudent, studentLevel: student.level, studentClass: student.class, yearStudyId: student.yearStudyId };
        });

        let data = {
          orderTitleId: self.orderTitleId,
          orderNumber: self.orderNumber,
          orderDescription: self.orderDescription,
          orderYear: self.orderYear,
          orderLevel: self.orderLevel,
          studentIds: studentIds,
          newStudentLevel: self.orderNewLevel,
          orderDate: self.orderDate,
          orderStatus: self.orderStatus,
          imagePath: self.uploadedImage,
          createdBy: 1,
          titleType: 3,
        };
        console.log(data);

        self.$http
          .post(`${self.$store.state.apiUrlRegistration}/addMultiAdministrativeOrder`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            self.adminOrderDialog = false;
            this.$store.state.studentSelectedList = [];
          });
      }
    },
    saveOrderDate(date) {
      this.$refs.orderDateMenu.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>

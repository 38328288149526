<template>
  <div>
    <v-form>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-text-field v-model="schoolName" color="accent" outlined label="اسم المدرسة" :disabled="!editable"> </v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-select
            v-model="graduationDate"
            outlined
            color="accent"
            label="سنة التخرج"
            :items="$store.state.yearStudies"
            item-text="year"
            item-value="idYearStudy"
            item-color="accent"
            return-object
            :disabled="!editable"
          >
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-checkbox v-model="haveSecondLanguage" label="هل لديه لغة اضافية"></v-checkbox>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="totalMarks" color="accent" outlined label="مجموع الدرجات" type="number" :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="lessonCount" color="accent" outlined label="عدد المواد" type="number" :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="secondLanguageMark" color="accent" outlined label="درجة اللغة الاضافية" type="number" :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="average" color="accent" outlined label="المعدل" type="number" disabled></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="examNumber" color="accent" outlined label="الرقم الامتحاني" :disabled="!editable"> </v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-select
            v-model="studentPassType"
            :items="$store.state.passTypes"
            item-value="idPassType"
            item-text="passName"
            color="accent"
            outlined
            label="الدور"
            item-color="accent"
            :disabled="!editable"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-select v-model="directorate" :items="directorateList" item-color="accent" color="accent" outlined label="المديرية" :disabled="!editable"></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-select
            v-model="studentCategory"
            :items="$store.state.studyCategories"
            item-value="idStudyCategory"
            item-text="categoryName"
            color="accent"
            outlined
            label="الاختصاص"
            return-object
            item-color="accent"
            :disabled="!editable"
            @change="categorySelected"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-select
            v-model="studySubCategoryId"
            :items="$store.state.studySubCategories"
            item-value="idStudySubCategory"
            item-text="subCategoryName"
            color="accent"
            outlined
            label="الفرع"
            item-color="accent"
            :disabled="!editable"
          >
          </v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="documentNumber" color="accent" outlined label="رقم الوثيقة" type="number" :disabled="!editable"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-menu ref="documentDateMenu" v-model="documentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
            <template #activator="{ on, attrs }">
              <v-text-field v-model="documentDate" label="تاريخ الوثيقة" readonly color="accent" outlined v-bind="attrs" :disabled="!editable" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="documentDate"
              :active-picker.sync="documentDateMenuActivePicker"
              :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="documentDigit" color="accent" outlined label="عدد الوثيقة" :disabled="!editable"> </v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-select
            v-model="certificateStatusId"
            :items="$store.state.allCertificateStatus"
            item-value="idCertificateStatus"
            item-text="certificateStatusName"
            color="accent"
            outlined
            label="حالة الشهادة"
            item-color="accent"
            :disabled="!editable"
            @change="certificateStatusSelected"
          ></v-select>
        </v-col>

        <!-- <v-col v-if="cookie.get('auth.user').roleId == 3" cols="12">
          <v-textarea v-model="certificateStatusDescription" color="accent" outlined label="نص التصديق" :disabled="!isSelectCertificatesStatus"></v-textarea>
        </v-col>
        <v-col v-else cols="12">
          <v-textarea v-model="certificateStatusDescription" color="accent" outlined label="نص التصديق" disabled> </v-textarea>
        </v-col> -->
      </v-row>

      <v-divider class="mb-5 mt-5" />

      <v-btn color="error" depressed large :disabled="row_classess" @click="editable = !editable">
        <span>{{ editable ? "الغاء التحديث" : "تحديث الطالب" }}</span>
      </v-btn>

      <v-btn v-if="editable" class="mr-5" depressed color="success" large @click.stop="saveStudentSchool">
        <span class="secondary--text">حفظ المعلومات</span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
// import _ from 'lodash'
import moment from "moment";
import * as cookie from "vue-cookies";
export default {
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cookie,
      editable: false,
      documentDateMenu: false,
      documentDateMenuActivePicker: null,

      schoolName: "",
      graduationDate: "",
      totalMarks: "",
      documentNumber: "",
      documentDate: "",
      examNumber: "",
      lessonCount: "",
      directorate: "",
      directorateList: [
        "لايوجد",
        "الكرخ الاولى",
        "الكرخ الثانية",
        "الكرخ الثالثة",
        "الرصافة الاولى",
        "الرصافة الثانية",
        "الرصافة الثالثة",
        "الانبار",
        "كردستان",
        "نينوى",
        "صلاح الدين",
        "النجف الاشرف",
        "كربلاء",
        "بابل",
        "ميسان",
        "البصرة",
        "ديالى",
        "كركوك",
        "واسط",
        "ذي قار",
        "القادسية",
        "المثنى",
        "الوافدين",
      ],
      studySubCategoryId: "",
      certificateStatusId: "",
      certificateStatusDescription: "لايوجد",

      selectCategory: [],
      selectSubCategory: [],
      studentCategory: [],
      studentSubCategory: "",

      selectCertificatesStatus: [],
      isSelectCategory: false,
      isSelectCertificatesStatus: false,

      selectPassType: [],
      studentPassType: "",
      documentDigit: "",

      studentSchool: [],
      schoolId: "",
      studentSection: "",
      haveSecondLanguage: false,
      secondLanguageMark: 0,
    };
  },

  computed: {
    average() {
      if (this.totalMarks === "" && this.lessonCount === "") {
        return "";
      } else {
        return ((this.secondLanguageMark * 0.08 + this.totalMarks) / this.lessonCount).toFixed(3);
      }
    },

    row_classess() {
      //   const userSection = this.$auth.user.sectionId;
      //   const orderSection = this.studentSection;
      //   return userSection !== orderSection;
      return false;
    },
  },

  watch: {
    documentDateMenu(val) {
      val && setTimeout(() => (this.documentDateMenuActivePicker = "YEAR"));
    },

    studentCategory(val) {
      this.categorySelected(val);
    },
  },

  created() {
    this.$http
      .get(`${this.$store.state.apiUrlRegistration}/studentSchoolInfo/${this.studentId}`, { headers: { Authorization: `Bearer ${this.$store.state.user}` } })
      .then((response) => {
        console.log(response.data);
        this.schoolName = response.data.schoolName;
        this.studentCategory = response.data.studyCategoryId;
        this.graduationDate = response.data.graduationDate;
        this.totalMarks = response.data.totalMarks;
        this.documentNumber = response.data.documentNumber;
        this.documentDate = moment(response.data.documentDate).format("YYYY-MM-DD");
        this.examNumber = response.data.examNumber;
        this.lessonCount = response.data.lessonCount;
        this.directorate = response.data.directorate;
        this.certificateStatusId = response.data.certificateStatusId;
        this.studySubCategoryId = response.data.studySubCategoryId;
        this.certificateStatusDescription = response.data.certificateStatusDescription;
        this.documentDigit = response.data.documentDigit;
        this.studentPassType = response.data.passTypeId;
        this.schoolId = response.data.idStudentSchool;
        this.haveSecondLanguage = response.data.isHaveSecond;
        this.secondLanguageMark = response.data.secondLangMark;
        //   }
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 404
            ? this.$toasted.error("لم يتم ادخال بيانات المدرسة للطالب", {
                duration: 3000,
                position: "top-center",
              })
            : this.$toasted.error("حدث خطأ في البيانات", {
                duration: 3000,
                position: "top-center",
              });
          console.log("Error status:", error.response.status);
          console.log("Error data:", error.response.data);
        } else {
          console.log("Error:", error.message);
        }
      });
  },

  methods: {
    save(date) {
      this.$refs.documentDateMenu.save(date);
    },

    categorySelected(category) {
      this.selectSubCategory = category.StudySubCategory;
      this.isSelectCategory = true;
    },

    certificateStatusSelected(status) {
      if (status === 1) {
        console.log("certification is working ...");
        this.isSelectCertificatesStatus = true;
      }
    },

    saveStudentSchool() {
      let self = this;
      const school = {
        schoolName: this.schoolName,
        graduationDate: this.graduationDate.idYearStudy ? this.graduationDate.idYearStudy : this.graduationDate,
        totalMarks: this.totalMarks,
        average: this.average * 1,
        documentNumber: this.documentNumber.toString(),
        documentDate: this.documentDate,
        lessonCount: this.lessonCount,
        directorate: this.directorate,
        studySubCategoryId: this.studySubCategoryId * 1,
        studentId: this.studentId,
        certificateStatusId: this.certificateStatusId,
        certificateStatusDescription: this.certificateStatusDescription,
        passTypeId: this.studentPassType,
        documentDigit: this.documentDigit,
        examNumber: this.examNumber,
        isHaveSecond: this.haveSecondLanguage,
        secondLangMark: this.secondLanguageMark,
      };
      if (!self.schoolId) {
        this.$http
          .post(`${this.$store.state.apiUrlRegistration}/addStudentSchool`, school, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              position: "top-center",
              duration: 3000,
            });
            this.editable = false;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            console.error(error);
          });
      } else {
        this.$http
          .put(`${this.$store.state.apiUrlRegistration}/studentSchool/${self.schoolId}`, school, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              position: "top-center",
              duration: 3000,
            });
            this.editable = false;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            console.error(error);
          });
      }

      // .then((response) => {})
      // .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>

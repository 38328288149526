<template>
  <v-row justify="center" v-if="$store.state.showDialog">
    <v-dialog v-model="$store.state.showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$store.state.showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.state.selectedStudent.item.studentName }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <StudentProfile :studentId="$store.state.selectedStudent.item.idStudent" />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import StudentProfile from "./../views/StudentProfile.vue";
export default {
  components: {
    StudentProfile,
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>

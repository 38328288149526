<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete v-model="provinceId" :items="$store.state.provinces" item-value="idProvince" item-text="provinceName" color="primary" label="المحافظة" outlined></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field v-model="district" color="primary" label="المنطقة" outlined></v-text-field>
      </v-col>

      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field v-model="avenue" color="primary" label="المحلة" outlined></v-text-field>
      </v-col>

      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field v-model="streetNumber" color="primary" label="رقم الزقاق" outlined></v-text-field>
      </v-col>

      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field v-model="houseNumber" color="primary" label="رقم الدار" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-btn depressed color="primary" large @click="saveAddress">
      <span class="secondary--text">حفظ المعلومات</span>
    </v-btn>

    <v-btn depressed color="accent" large class="mr-5 ml-5" @click="$store.state.studentStep = 3">
      <span class="error--text">رجوع</span>
    </v-btn>

    <v-btn depressed color="accent" large @click="$store.state.studentStep = 5">
      <span class="info--text">تخطي</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      students: [],

      provinceId: "",
      provinces: [],

      district: "",
      avenue: "",
      houseNumber: "",
      streetNumber: "",
    };
  },

  computed: {
    studentId() {
      return this.$store.getters.getLastStudentRegistered;
    },
  },

  methods: {
    saveAddress() {
      let self = this;

      console.log("hi");

      self.$http
        .post(
          `${self.$store.state.apiUrlRegistration}/addAddress`,
          {
            provinceId: this.provinceId,
            district: this.district,
            avenue: this.avenue,
            houseNumber: this.houseNumber,
            streetNumber: this.streetNumber,
            studentId: self.$store.state.newStudentId,
          },
          { headers: { Authorization: `Bearer ${self.$store.state.user}` } }
        )
        .then((res) => {
          console.log(res);
          this.$toasted.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
          self.$store.state.studentStep = 5;
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في حفظ البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-form ref="studentRequiredForm" v-model="studentRequiredForm" class="pt-3 pb-3" lazy-validation>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-text-field v-model="schoolName" color="primary" outlined label="اسم المدرسة"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-select
          v-model="graduationDate"
          outlined
          color="primary"
          label="سنة التخرج"
          :items="$store.state.yearStudies"
          item-text="year"
          item-value="idYearStudy"
          item-color="primary"
          return-object
        ></v-select>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="totalMarks" color="primary" outlined label="مجموع الدرجات" type="number"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="lessonCount" color="primary" outlined label="عدد المواد" type="number"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="average" color="primary" outlined label="المعدل" type="number" disabled></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="examNumber" color="primary" outlined label="الرقم الامتحاني"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-select v-model="studentPassType" :items="$store.state.passTypes" item-value="idPassType" item-text="passName" color="primary" outlined label="الدور" item-color="primary"></v-select>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-select v-model="directorate" :items="directorateList" item-color="primary" color="primary" outlined label="المديرية"></v-select>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-select
          v-model="studentCategory"
          :items="$store.state.studyCategories"
          item-value="idStudyCategory"
          item-text="categoryName"
          color="primary"
          outlined
          label="الاختصاص"
          return-object
          item-color="primary"
          @change="categorySelected"
        ></v-select>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-select
          v-model="studySubCategoryId"
          :items="selectSubCategory"
          item-value="idStudySubCategory"
          item-text="subCategoryName"
          color="primary"
          outlined
          label="الفرع"
          item-color="primary"
          :disabled="!isSelectCategory"
        ></v-select>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="documentNumber" color="primary" outlined label="رقم الوثيقة" type="number"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-menu ref="documentDateMenu" v-model="documentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template #activator="{ on, attrs }">
            <v-text-field v-model="documentDate" label="تاريخ الوثيقة" readonly color="primary" outlined v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker
            v-model="documentDate"
            :active-picker.sync="documentDateMenuActivePicker"
            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field v-model="documentDigit" color="primary" outlined label="عدد الوثيقة"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-select
          v-model="certificateStatusId"
          :items="$store.state.allCertificateStatus"
          item-value="idCertificateStatus"
          item-text="certificateStatusName"
          color="primary"
          outlined
          label="حالة الشهادة"
          item-color="primary"
          @change="certificateStatusSelected"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <!-- <v-textarea v-if="cookie.get('auth.user').roleId == 3" v-model="certificateStatusDescription" color="accent" outlined label="نص التصديق" :disabled="!isSelectCertificatesStatus"></v-textarea> -->
      </v-col>
    </v-row>

    <v-divider class="mb-5 mt-5" />

    <v-btn depressed color="primary" :disabled="!studentRequiredForm" large @click.stop="saveStudentSchool">
      <span class="accent--text">حفظ المعلومات</span>
    </v-btn>

    <v-btn depressed color="accent" large class="mr-5 ml-5" @click="$store.state.studentStep = 2">
      <span class="error--text">رجوع</span>
    </v-btn>

    <v-btn depressed color="accent" large @click="$store.state.studentStep = 4">
      <span class="info--text">تخطي</span>
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      studentRequiredForm: false,
      rules: [(v) => !!v || "لا يمكن ترك الحقل فارغ"],

      documentDateMenu: false,
      documentDateMenuActivePicker: null,

      schoolName: "",
      graduationDate: null,
      totalMarks: "",
      documentNumber: 0,
      documentDate: "",
      lessonCount: "",
      directorate: "",
      directorateList: [
        "لا يوجد",
        "الكرخ الاولى",
        "الكرخ الثانية",
        "الكرخ الثالثة",
        "الرصافة الاولى",
        "الرصافة الثانية",
        "الرصافة الثالثة",
        "الانبار",
        "كردستان",
        "نينوى",
        "صلاح الدين",
        "النجف الاشرف",
        "كربلاء",
        "بابل",
        "ميسان",
        "البصرة",
        "ديالى",
        "كركوك",
        "واسط",
        "ذي قار",
        "القادسية",
        "المثنى",
      ],
      studySubCategoryId: "",
      certificateStatusId: 6,
      certificateStatusDescription: "لايوجد",
      examNumber: 0,

      selectCategory: [],
      selectSubCategory: [],
      studentCategory: [],
      studentSubCategory: "",

      selectCertificatesStatus: [],
      isSelectCategory: false,
      isSelectCertificatesStatus: false,

      selectPassType: [],
      studentPassType: "",
      documentDigit: "0",

      selectYear: [],

      studentSchool: [],
      schoolId: "",

      students: [],
    };
  },

  computed: {
    average() {
      if (this.totalMarks === "" && this.lessonCount === "") {
        return "";
      } else {
        return (this.totalMarks / this.lessonCount).toFixed(3);
      }
    },

    studentId() {
      return this.$store.getters.getLastStudentRegistered;
    },
  },

  watch: {
    documentDateMenu(val) {
      val && setTimeout(() => (this.documentDateMenuActivePicker = "YEAR"));
    },
  },

  methods: {
    save(date) {
      this.$refs.documentDateMenu.save(date);
    },

    categorySelected(category) {
      let self = this;
      this.selectSubCategory = self.$store.state.studySubCategories.filter((subCategory) => subCategory.studyCategoryId == self.studentCategory.idStudyCategory);
      console.log(self.studentCategory);
      this.isSelectCategory = true;
    },

    certificateStatusSelected(status) {
      if (status === 1) {
        this.isSelectCertificatesStatus = true;
      }
    },

    saveStudentSchool() {
      let self = this;

      const school = {
        schoolName: this.schoolName,
        graduationDate: this.graduationDate.idYearStudy,
        totalMarks: this.totalMarks,
        average: this.average,
        documentNumber: this.documentNumber,
        documentDate: this.documentDate,
        lessonCount: this.lessonCount,
        directorate: this.directorate,
        studySubCategoryId: this.studySubCategoryId * 1,
        studentId: self.$store.state.newStudentId,
        certificateStatusId: this.certificateStatusId * 1,
        certificateStatusDescription: this.certificateStatusDescription,
        passTypeId: this.studentPassType,
        documentDigit: this.documentDigit * 1,
        examNumber: this.examNumber,
      };

      this.$http
        .post(`${self.$store.state.apiUrlRegistration}/addStudentSchool`, school, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          this.$toasted.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });
          self.$store.state.studentStep = 4;
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في حفظ البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="profile-page" v-if="show">
    <v-card color="secondary" class="shadow-1 rounded-1 mt-5">
      <v-tabs v-model="tabs" center-active color="primary" grow centered right>
        <v-tab>المعلومات الاساسية</v-tab>
        <v-tab>معلومات ولي الامر</v-tab>
        <v-tab>معلومات المدرسة</v-tab>
        <v-tab>معلومات السكن</v-tab>
        <v-tab>الاوامر الادارية</v-tab>
        <v-tab>الصورة الشخصية و الوثيقة</v-tab>

        <v-tabs-items v-model="tabs" class="pa-5 border-top">
          <v-tab-item>
            <ProfileTabsOne :studentId="Number(studentId)" />
          </v-tab-item>

          <v-tab-item>
            <ProfileTabsTwo :studentId="Number(studentId)" />
          </v-tab-item>

          <v-tab-item>
            <ProfileTabsThree :studentId="Number(studentId)" />
          </v-tab-item>

          <v-tab-item>
            <ProfileTabsFour :studentId="Number(studentId)" />
          </v-tab-item>

          <v-tab-item>
            <ProfileTabsFive :studentId="Number(studentId)" />
          </v-tab-item>

          <v-tab-item>
            <ProfileTabsSix :studentId="Number(studentId)" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ProfileTabsOne from "./../components/studentProfile/ProfileTabsOne.vue";
import ProfileTabsTwo from "./../components/studentProfile/ProfileTabsTwo.vue";
import ProfileTabsThree from "./../components/studentProfile/ProfileTabsThree.vue";
import ProfileTabsFour from "./../components/studentProfile/ProfileTabsFour.vue";
import ProfileTabsFive from "./../components/studentProfile/ProfileTabsFive.vue";
import ProfileTabsSix from "./../components/studentProfile/ProfileTabsSix.vue";
export default {
  components: {
    ProfileTabsFive,
    ProfileTabsFour,
    ProfileTabsOne,
    ProfileTabsSix,
    ProfileTabsTwo,
    ProfileTabsThree,
  },
  props: {
    studentId: Number,
  },
  data() {
    return {
      tabs: null,
      student: {},
      show: false,
    };
  },

  mounted() {
    this.getStudents();
  },

  methods: {
    async getStudents() {
      let self = this;
      console.log(this.studentId);

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/studentProfile/${this.studentId}/${this.$store.state.selectedStudent.item.studentStatusId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.student = res.data;
          self.show = true;
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

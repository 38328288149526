<template>
  <div>
    <!-- <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->

    <v-card color="whiteColor" class="shadow-navbar rounded-1">
      <v-toolbar color="primary" dark class="shadow-navbar rounded-1 mb-0 mt-5">
        <h4>فرز الاوامر الادارية</h4>

        <v-spacer />

        <h4 class="mr-10">عدد الاوامر الادارية: {{ ordersCount }}</h4>
      </v-toolbar>
      <div class="pa-10">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              :items="$store.state.levels"
              v-model="orderLevel"
              item-value="value"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="مرحلة الامر"
              name="orderLevel"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              name="reportOrderTitleId"
              v-model="orderTitleId"
              :items="$store.state.orderTitles"
              item-text="title"
              item-value="idOrderTitle"
              item-color="primary"
              color="primary"
              outlined
              label="عنوان الامر"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-text-field name="reportOrderNumber" v-model="orderNumber" color="primary" outlined label="رقم الامر" clearable dense hide-details></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              v-model="reportOrderYear"
              name="reportOrderYear"
              :items="$store.state.yearStudies"
              item-value="idYearStudy"
              item-text="year"
              item-color="primary"
              color="primary"
              outlined
              label="سنة الامر"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-menu ref="orderDateMenuModel" v-model="orderDateMenuModel" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="reportOrderDate" name="reportOrderDate" outlined color="primary" label="تاريخ الامر" v-bind="attrs" clearable dense hide-details v-on="on"></v-text-field>
              </template>

              <v-date-picker
                v-model="reportOrderDate"
                :active-picker.sync="orderDateActivePicker"
                :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="saveOrderDate"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-autocomplete
              v-model="sectionId"
              :items="$store.state.sections"
              item-value="idSection"
              item-text="sectionName"
              color="primary"
              outlined
              item-color="primary"
              label="القسم"
              name="sectionId"
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              :items="$store.state.yearStudies"
              v-model="registerYearId"
              item-value="idYearStudy"
              item-text="year"
              color="primary"
              outlined
              item-color="primary"
              label="سنة القبول"
              name="registerYearId"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              :items="$store.state.studentStatus"
              v-model="studentStatusId"
              item-value="idStudentStatus"
              item-text="statusName"
              color="primary"
              outlined
              item-color="primary"
              label="حالة الطالب"
              name="studentStatusId"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              outlined
              color="primary"
              label="الجنس"
              :items="$store.state.genders"
              item-text="text"
              item-value="text"
              item-color="primary"
              v-model="gender"
              name="gender"
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              :items="$store.state.studyTypes"
              v-model="studyType"
              item-value="value"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="نوع الدراسة"
              name="studyType"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              :items="$store.state.levels"
              v-model="studentLevel"
              item-value="value"
              item-text="text"
              color="primary"
              outlined
              item-color="primary"
              label="مرحلة الطالب"
              name="studentLevel"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>

        <v-btn @click="searchOrder" large color="background" class="mt-5" block depressed>
          <span class="secondary--text">بحث</span>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studentLevel: "",
      orderTitleId: "",
      orderNumber: "",
      reportOrderYear: "",
      sectionId: "",
      registerYearId: "",
      studentStatusId: "",
      gender: "",
      studyType: "",
      orderLevel: "",
      orderDateMenuModel: false,
      reportOrderDate: null,
      orderDateActivePicker: null,
      ordersCount: 0,
    };
  },
  methods: {
    saveOrderDate(date) {
      this.$refs.orderDateMenuModel.save(date);
    },
    searchOrder() {
      let self = this;
      let searchQuery = {};

      if (self.studentLevel) {
        searchQuery.studentLevel = self.studentLevel;
      }

      if (self.sectionId) {
        searchQuery.sectionId = self.sectionId;
      }

      if (self.studyType) {
        searchQuery.studyType = self.studyType;
      }

      if (self.orderTitleId) {
        searchQuery.orderTitleId = self.orderTitleId;
      }

      if (self.gender) {
        searchQuery.gender = self.gender;
      }

      if (self.orderNumber) {
        searchQuery.orderNumber = self.orderNumber;
      }

      if (self.registerYearId) {
        searchQuery.registerYearId = self.registerYearId;
      }

      if (self.orderLevel) {
        searchQuery.orderLevel = self.orderLevel;
      }

      if (self.studentStatusId) {
        searchQuery.studentStatusId = self.studentStatusId;
      }

      if (self.reportOrderYear) {
        searchQuery.orderYear = self.reportOrderYear;
      }

      if (self.reportOrderDate) {
        searchQuery.orderDate = self.reportOrderDate;
      }

      if (
        !self.orderLevel &&
        !self.sectionId &&
        !self.studyType &&
        !self.orderTitleId &&
        !self.gender &&
        !self.studentLevel &&
        !self.registerYearId &&
        !self.orderNumber &&
        !self.studentStatusId &&
        !self.reportOrderYear &&
        !self.reportOrderDate
      ) {
        this.$toasteded.error("الرجاء اختيار احد المحددات");
        self.overlay = false;
        return;
      }

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/filterAdministrativeOrders?search=${JSON.stringify(searchQuery)}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.overlay = false;
          self.ordersCount = res.data.length;
          this.$emit("clicked", res.data);
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });

      console.log(searchQuery);
    },
  },
};
</script>

<style lang="scss" scoped></style>

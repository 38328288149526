<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field :value="calculateCollegeNumber()" outlined color="primary" label="الرقم الاحصائي" disabled></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-text-field v-model="studentName" outlined color="primary" label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-text-field v-model="studentEnglishName" outlined color="primary" label="الاسم الانجليزي" :rules="studentRequiredFormRules"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-menu ref="dobMenuRef" v-model="dobMenuModel" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template #activator="{ on, attrs }">
            <v-text-field v-model="studentDob" outlined color="primary" label="تاريخ الميلاد" v-bind="attrs" :rules="studentRequiredFormRules" v-on="on"></v-text-field>
          </template>

          <v-date-picker
            v-model="studentDob"
            :active-picker.sync="dobActivePicker"
            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="saveDob"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-text-field v-model="studentMail" outlined color="primary" label="البريد الالكتروني" :rules="studentRequiredFormRules"></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete v-model="studentNationality" outlined color="primary" label="الجنسية" :rules="studentRequiredFormRules" :items="listOfNational" item-color="accent"></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentGender"
          outlined
          color="primary"
          label="الجنس"
          :items="$store.state.genders"
          item-text="text"
          item-value="text"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentSection"
          outlined
          color="primary"
          label="القسم"
          :items="$store.state.sections"
          item-text="sectionName"
          item-value="idSection"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentStudyType"
          outlined
          color="primary"
          label="نوع الدراسة"
          :items="$store.state.studyTypes"
          item-text="text"
          item-value="value"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentLevel"
          outlined
          color="primary"
          label="المرحلة"
          :items="$store.state.levels"
          item-text="text"
          item-value="value"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentAcceptedType"
          outlined
          color="primary"
          label="نوع القبول"
          :items="$store.state.acceptedTypes"
          item-text="acceptedName"
          item-value="idAcceptedType"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentRegisterYear"
          outlined
          color="primary"
          label="سنة القبول"
          :items="$store.state.yearStudies"
          item-text="year"
          item-value="idYearStudy"
          item-color="primary"
          :rules="studentRequiredFormRules"
          return-object
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="studentStatus"
          outlined
          color="primary"
          label="حالة الطالب"
          :items="$store.state.studentStatus"
          item-text="statusName"
          item-value="idStudentStatus"
          item-color="primary"
          :rules="studentRequiredFormRules"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-textarea v-model="studentNote" outlined color="primary" label="الملاحظات" :rules="studentRequiredFormRules"></v-textarea>
      </v-col>
    </v-row>

    <v-btn depressed color="success" large @click.stop="registerStudents">
      <span class="secondary--text">حفظ المعلومات</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BasicStudentInfo",
  data() {
    return {
      dobActivePicker: null,
      dobMenuModel: false,
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],

      studentName: "",
      studentEnglishName: "none",
      studentDob: "",
      studentGender: "",
      studentMail: "turath.student@turath.edu.iq",
      studentNationality: "عراقية",
      studentSection: "",
      studentStudyType: "",
      studentAcceptedType: "",
      studentLevel: "",
      studentRegisterYear: {
        year: "2021-2022",
      },
      studentStatus: "",
      studentNote: "لا يوجد",
      listOfNational: ["عراقية", "مصري", "سوري", "اردني", "سعودي", "كويتي", "ليبي", "لبناني", "فلسطيني", "سوداني", "جزائري", "مغربي"],

      selectLevel: [
        {
          text: "المرحلة الاولى",
          value: 1,
        },
        {
          text: "المرحلة الثانية",
          value: 2,
        },
        {
          text: "المرحلة الثالثة",
          value: 3,
        },
        {
          text: "المرحلة الرابعة",
          value: 4,
        },
        {
          text: "المرحلة الخامسة",
          value: 5,
        },
      ],
      selectSection: [],
      selectAcceptedType: [],
      selectRegisterYear: [],
      selectStatus: [],

      sectionCode: "",
      sectionCount: "",
      collegeNumber: null,
      currentCount: {
        studentsCount: 0,
      },
    };
  },

  watch: {
    dobMenuModel(val) {
      val && setTimeout(() => (this.dobActivePicker = "YEAR"));
    },
  },

  created() {
    console.log(this.$store.state.sections);
    // this.$http.get(`studentsSectionCount?sectionId=${loggedInUser.sectionId}`).then((status) => {
    //   this.currentCount = status.data;
    // });
  },

  methods: {
    saveDob(date) {
      this.$refs.dobMenuRef.save(date);
    },
    calculateCollegeNumber() {
      const currentSection = this.selectSection.filter((x) => x.idSection === this.studentSection);
      const year = this.studentRegisterYear.year.split("-")[0].slice(-2);
      const sectionCode = currentSection.length > 0 ? currentSection[0].code : 0;
      const dob = this.studentDob ? this.studentDob.split("-")[0].slice(-2) : 0;
      const count = Math.floor(Math.random() * 1000) + 1;
      return `23` + year + sectionCode + dob + count + Math.floor(Math.random() * 1000);
    },
    async registerStudents() {
      let self = this;
      console.log(self.studentSection);
      try {
        const student = {
          studentName: this.studentName,
          englishName: this.studentEnglishName,
          mail: this.studentMail,
          dob: this.studentDob,
          sectionId: this.studentSection,
          nationality: this.studentNationality,
          gender: this.studentGender,
          studyType: this.studentStudyType * 1,
          acceptedTypeId: this.studentAcceptedType * 1,
          registerYearId: this.studentRegisterYear.idYearStudy,
          studentStatusId: this.studentStatus,
          note: this.studentNote,
          collegeNumber: this.calculateCollegeNumber(),
          studentLevel: {
            level: this.studentLevel,
            class: "A",
            yearStudyId: this.studentRegisterYear.idYearStudy,
          },
        };

        console.log(student);

        self.$http.post(`${self.$store.state.apiUrlRegistration}/addStudentWithLevel`, student, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }).then((resStudent) => {
          console.log(resStudent);
          self.$store.state.newStudentId = resStudent.data.id;
          localStorage.setItem("newStudentId", resStudent.data.id);
          self.$store.state.studentStep = 2;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

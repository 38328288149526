<template>
  <div>
    <v-dialog v-model="lightBox" fullscreen>
      <v-card class="shadow-1 rounded-1" color="secondary">
        <v-toolbar class="shadow-1 rounded-1" color="primary">
          <v-btn color="success" depressed large @click="saveAndPrint()">
            <span>حفظ الصورة</span>
          </v-btn>

          <v-spacer />

          <v-btn color="secondary" icon @click="lightBox = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div id="printContent">
          <img class="align-end" :src="selectedImage" height="900" width="600" />
        </div>
      </v-card>
    </v-dialog>
    <v-form>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-select
            v-model="imageType"
            :items="$store.state.imageTypes"
            item-text="typeName"
            item-value="idImageType"
            item-color="accent"
            outlined
            color="accent"
            label="نوع الصورة"
            @change="isTypeSelected = true"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-file-input v-model="attachment" color="accent" outlined label="اختر الملف..." :disabled="!isTypeSelected" @change="isFileSelected = true"></v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <v-btn depressed color="accent" large :disabled="!isFileSelected" @click="saveFile">
      <span class="secondary--text">حفظ المعلومات</span>
    </v-btn>

    <div class="mt-10">
      <v-divider class="mb-5" />
      <v-row>
        <v-col
          v-for="images in studentImages"
          :key="images.idStudentImage"
          cols="12"
          xs="12"
          sm="12"
          :md="images.imageTypeId === 2 ? '4' : '4'"
          :lg="images.imageTypeId === 2 ? '4' : '4'"
          :xl="images.imageTypeId === 2 ? '4' : '4'"
        >
          <v-card class="shadow-1 rounded-1" color="secondary">
            <img
              :key="images.idStudentImage"
              class="align-end imageUser"
              :src="$store.state.apiUrlRegistration + '/' + images.imagePath"
              height="300px"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              @dblclick="pickImage($store.state.apiUrlRegistration + '/' + images.imagePath)"
            />
            <v-card-title>
              <span class="primary--text">{{ images.typeName }}</span>
              <v-spacer />
              <v-btn fab color="error" :disabled="matchSections" @click="deleteImage(images.idStudentImage)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div id="hiddenPrinter" class="d-none"></div>
  </div>
</template>

<script>
export default {
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imageType: "",
      attachment: null,
      isTypeSelected: false,
      isFileSelected: false,
      studentImages: [],
      selectedImage: "",
      lightBox: false,
      sectionId: null,
    };
  },

  computed: {
    matchSections() {
      // const userSection = this.$auth.user.sectionId;
      // const studentSection = this.sectionId;

      // return studentSection === userSection;
      return false;
    },
  },

  created() {
    // .then((response) => {})
    // .catch((error) => {});

    this.getStudentImage();
  },

  methods: {
    async getStudentImage() {
      let self = this;
      try {
        const images = await this.$http.get(`${this.$store.state.apiUrlRegistration}/allStudentImage/${this.studentId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        });
        this.studentImages = images.data;
      } catch (e) {
        console.error(e);
        this.$toasted.error("حدث خطأ في جلب المعلومات", {
          duration: 3000,
          position: "top-center",
        });
      }
    },

    pickImage(url) {
      this.selectedImage = url;
      this.lightBox = true;
    },

    saveAndPrint() {
      // const printContent = this.selectedImage
      const windowUrl = "about:blank";
      const uniqueName = new Date();
      const windowName = "Print" + uniqueName.getTime();

      const printWindow = window.open(windowUrl, windowName, "left=50000,top=50000,width=0,height=0,status=yes");

      const newImage = document.createElement("img");
      newImage.setAttribute("src", this.selectedImage);

      printWindow.document.body.append(newImage);
      // printWindow.document.close()
      printWindow.focus();
      printWindow.print();
      // printWindow.close();

      this.lightBox = false;
    },

    deleteImage(id) {
      let self = this;

      this.$http
        .delete(`${this.$store.state.apiUrlRegistration}/studentImage/${id}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.$toasted.success("تم حذف الصورة", {
            duration: 3000,
            position: "top-center",
          });

          this.getStudentImage();
        })
        .catch((error) => {
          this.$toasted.error("حدث خطأ في  المعلومات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    saveFile() {
      let self = this;
      const data = {
        imagePath: "",
        imageTypeId: this.imageType,
        studentId: this.studentId,
      };

      const file = new FormData();
      file.append("attachment", this.attachment);

      this.$http
        .post(`${this.$store.state.apiUrlRegistration}/uploadImage`, file)
        .then((path) => {
          data.imagePath = path.data.imagePath;
          this.$http
            .post(`${this.$store.state.apiUrlRegistration}/addStudentImage`, data, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((result) => {
              this.$toasted.success("تم حفظ المعلومات", {
                duration: 3000,
                position: "top-center",
              });

              this.getStudentImage();
            })
            .catch((error) => {
              this.$toasted.error("حدث خطأ في حفظ المعلومات", {
                duration: 3000,
                position: "top-center",
              });
              console.error(error);
            });
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("حدث خطأ في حفظ الصورة", {
            duration: 3000,
            position: "top-center",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.imageUser {
  width: 100%;
}
</style>

<template>
  <div>
    <v-container class="container not-printable" fluid>
      <SearchOrder style="display: inline-block" @clicked="onSearch" />
    </v-container>

    <v-container class="container not-printable" fluid>
      <OrderTable :orders="adminOrders" />
    </v-container>
  </div>
</template>

<script>
import SearchOrder from "@/components/orders/SearchOrder.vue";
import OrderTable from "@/components/orders/OrderTable.vue";
export default {
  components: {
    SearchOrder,
    OrderTable,
  },
  data() {
    return {
      adminOrders: [],
      showTable: false,
    };
  },
  methods: {
    onSearch(value) {
      console.log(value);
      this.adminOrders = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Students from "../views/Students.vue";
import AdminOrder from "../views/AdminOrder.vue";
import Reports from "../views/Reports.vue";
import AddStudent from "../views/AddStudent.vue";
import StudentProfile from "../views/StudentProfile.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/add-student",
    name: "addStudent",
    component: AddStudent,
  },
  {
    path: "/students",
    name: "students",
    component: Students,
  },
  {
    path: "/student-profile/:id",
    name: "StudentProfile",
    component: StudentProfile,
  },
  {
    path: "/order",
    name: "order",
    component: AdminOrder,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div>
    <v-form @submit.prevent="saveStudentResponsible">
      <v-row>
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-select v-model="responsibleName" color="accent" :items="phoneTypes" label="نوع الهاتف" outlined item-color="accent" :disabled="disableResponsibleInfoArea"></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
          <v-text-field v-model="responsiblePhone" color="accent" label="رقم ولي الامر" outlined :disabled="disableResponsibleInfoArea"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn v-if="!disableResponsibleInfoArea" color="primary" depressed large block @click="saveStudentResponsible">
            <span class="secondary--text">حفظ المعلومات</span>
          </v-btn>

          <v-btn v-if="disableResponsibleInfoArea" color="accent" depressed large block :disabled="row_classess" @click="disableResponsibleInfoArea = false">
            <span class="secondary--text">تعديل المعلومات</span>
          </v-btn>
        </v-col>

        <v-col cols="12" class="mb-5">
          <v-card elevation="0">
            <v-list v-for="responsible in responsibleInfo" :key="responsible.idStudentResponsible" nav dense outlined style="margin: 10px">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="stepper lighten-1"> mdi-phone </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="2">{{ responsible.responsibleName }}</v-col>
                      <v-col cols="10">{{ responsible.responsiblePhone }}</v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon :disabled="row_classess" @click="deletePhoneNumber(responsible)">
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="nationalNumber" color="accent" label="رقم الجنسية" outlined :disabled="disableNationalInfoArea"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field v-model="motherName" color="accent" label="اسم الام" outlined :disabled="disableNationalInfoArea"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-text-field v-model="religion" color="accent" label="الديانة" outlined :disabled="disableNationalInfoArea"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn v-if="!disableNationalInfoArea" color="primary" depressed large block @click="saveStudentNational">
            <span class="secondary--text">حفظ المعلومات</span>
          </v-btn>

          <v-btn v-if="disableNationalInfoArea" color="accent" depressed large block :disabled="row_classess" @click="disableNationalInfoArea = false">
            <span class="secondary--text">تعديل المعلومات</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
          <v-text-field v-model="nationalityCertificateNumber" color="accent" label="رقم شهادة الجنسية" outlined :disabled="disableNationalityCertificateArea"></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <v-btn v-if="!disableNationalityCertificateArea" color="primary" depressed large block @click="saveStudentNationalCertificate">
            <span class="secondary--text">حفظ المعلومات</span>
          </v-btn>

          <v-btn v-if="disableNationalityCertificateArea" color="accent" depressed large block :disabled="row_classess" @click="disableNationalityCertificateArea = false">
            <span class="secondary--text">تعديل المعلومات</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      disableNationalInfoArea: true,
      disableResponsibleInfoArea: true,
      disableNationalityCertificateArea: true,
      responsibleName: "",
      responsiblePhone: "",
      nationalityCertificateNumber: "",
      nationalNumber: "",
      religion: "مسلم",
      motherName: "",
      phoneTypes: ["رقم الام", "رقم الاب", "رقم الاقارب", "رقم شخصي"],
      responsibleInfo: [],
      idNationalityCertificate: "",
      idNationalInfo: "",
      studentSection: "",
      allStudentResponsibleData: null,
    };
  },

  computed: {
    row_classess() {
      //   const userSection = this.$auth.user.sectionId;
      //   const orderSection = this.studentSection;
      //   return userSection !== orderSection;
      return false;
    },
  },

  created() {
    this.fetchStudents();
  },

  methods: {
    fetchStudents() {
      let self = this;
      this.$http
        .get(`${self.$store.state.apiUrlRegistration}/studentResponsibleData/${this.studentId}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((student) => {
          console.log(student.data);
          this.allStudentResponsibleData = student.data;
          this.responsibleInfo = student.data.studentResponsible;
          if (student.data.nationalInfo !== null) {
            this.motherName = student.data.nationalInfo.motherName;
            this.nationalNumber = student.data.nationalInfo.nationalNumber;
            this.religion = student.data.nationalInfo.religion;
            this.idNationalInfo = student.data.nationalInfo.idNationalInfo;
          }

          if (student.data.nationalityCertificate !== null) {
            this.nationalityCertificateNumber = student.data.nationalityCertificate.nationalityNumber;
            this.idNationalityCertificate = student.data.nationalityCertificate.idNationalityCertificate;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("حدث خطأ في البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    saveStudentResponsible() {
      let self = this;
      if (self.responsibleName && self.responsiblePhone) {
        this.$http
          .post(
            `${this.$store.state.apiUrlRegistration}/addStudentResponsible`,
            {
              responsibleName: this.responsibleName,
              responsiblePhone: this.responsiblePhone,
              studentId: this.studentId,
            },
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((response) => {
            console.log(response);
            this.responsibleInfo.push({
              responsibleName: this.responsibleName,
              responsiblePhone: this.responsiblePhone,
              studentId: this.studentId,
            });
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            this.disableResponsibleInfoArea = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toasted.error("الرجاء ادخال المعلومات", {
          duration: 3000,
          position: "top-center",
        });
      }
    },

    saveStudentNational() {
      let self = this;
      if (this.allStudentResponsibleData.nationalInfo == null) {
        if (self.nationalNumber && self.motherName && self.religion) {
          this.$http
            .post(
              `${this.$store.state.apiUrlRegistration}/addNationalInfo`,
              {
                nationalNumber: this.nationalNumber,
                motherName: this.motherName,
                religion: this.religion,
                studentId: this.studentId,
              },
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            )
            .then((response) => {
              this.$toasted.success("تم حفظ المعلومات", {
                duration: 3000,
                position: "top-center",
              });
              this.disableNationalInfoArea = true;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$toasted.error("حدث خطأ في البيانات", {
            duration: 3000,
            position: "top-center",
          });
        }
      } else {
        this.$http
          .put(
            `${this.$store.state.apiUrlRegistration}/nationalInfo/${self.allStudentResponsibleData.nationalInfo.idNationalInfo}`,
            {
              nationalNumber: this.nationalNumber,
              motherName: this.motherName,
              religion: this.religion,
              studentId: this.studentId,
            },
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            this.disableNationalInfoArea = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    saveStudentNationalCertificate() {
      let self = this;
      if (!self.idNationalityCertificate) {
        this.$http
          .post(
            `${this.$store.state.apiUrlRegistration}/addNationalityCertificate`,
            {
              nationalityNumber: this.nationalityCertificateNumber,
              idNationalityCertificate: this.idNationalityCertificate,
              studentId: this.studentId,
            },
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });

            this.disableNationalityCertificateArea = true;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في البيانات", {
              duration: 3000,
              position: "top-center",
            });
          });
      } else {
        this.$http
          .put(
            `${this.$store.state.apiUrlRegistration}/nationalityCertificate/${self.idNationalityCertificate}`,
            {
              nationalityNumber: this.nationalityCertificateNumber,
              idNationalityCertificate: this.idNationalityCertificate,
              studentId: this.studentId,
            },
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((response) => {
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });

            this.disableNationalityCertificateArea = true;
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في البيانات", {
              duration: 3000,
              position: "top-center",
            });
          });
      }
    },

    deletePhoneNumber(item) {
      let self = this;
      try {
        this.$http
          .delete(`${this.$store.state.apiUrlRegistration}/studentResponsible/${item.idStudentResponsible}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            this.responsibleInfo.splice(item, 1);
            this.$toasted.success("تم حذف الرقم بنجاح", {
              duration: 3000,
              position: "top-center",
            });
          })
          .catch((e) => {
            this.$toasted.error("حدث خطأ في حذف البيانات", {
              duration: 3000,
              position: "top-center",
            });
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

import { render, staticRenderFns } from "./StudentProfile.vue?vue&type=template&id=5899e7fb&scoped=true&"
import script from "./StudentProfile.vue?vue&type=script&lang=js&"
export * from "./StudentProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5899e7fb",
  null
  
)

export default component.exports
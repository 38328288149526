<template>
  <div>
    <v-dialog v-model="lightBox" fullscreen>
      <v-card class="shadow-1 rounded-1" color="secondary">
        <v-toolbar class="shadow-1 rounded-1" color="primary">
          <v-btn color="success" depressed large @click="saveAndPrint()">
            <span>حفظ الصورة</span>
          </v-btn>

          <v-spacer />

          <v-btn color="secondary" icon @click="lightBox = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div id="printContent">
          <img class="align-end" :src="selectedImage" height="900" width="600" />
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openEditDialog" persistent transition="slide-y-transition" max-width="750px">
      <v-card class="shadow-1 rounded-1" color="secondary">
        <v-toolbar color="primary" class="shadow-1 rounded-1">
          <h4>تحديث الامر الاداري</h4>

          <v-spacer />

          <v-btn icon color="accent" @click="openEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-5">
          <v-form ref="editForm" lazy-validation @submit.prevent="updateOrder">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-autocomplete
                  v-model="editData.title"
                  :items="orderTitles"
                  color="accent"
                  item-color="accent"
                  item-value="idOrderTitle"
                  item-text="title"
                  outlined
                  label="عنوان الامر الاداري"
                  :rules="rules"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field v-model="editData.number" color="accent" outlined label="رقم الامر الاداري" :rules="rules" clearable></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-select v-model="editData.level" :items="levels" color="accent" item-color="accent" outlined label="مرحلة الامر الاداري" :rules="rules" clearable></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-select
                  v-model="editData.year"
                  :items="yearStudies"
                  color="accent"
                  item-color="accent"
                  item-value="idYearStudy"
                  item-text="year"
                  outlined
                  label="سنة الامر الاداري"
                  :rules="rules"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-menu ref="editDataMenu" v-model="editData.menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="editData.date" label="تاريخ الامر الاداري" readonly v-bind="attrs" outlined color="accent" clearable v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editData.date"
                    :active-picker.sync="editData.activePicker"
                    :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xs="12" sm="12" :md="editData.status === 5 ? '6' : '12'" :lg="editData.status === 5 ? '6' : '12'" :xl="editData.status === 5 ? '6' : '12'">
                <v-select
                  v-model="editData.status"
                  :items="studentsStatus"
                  color="accent"
                  item-color="accent"
                  item-value="idStudentStatus"
                  item-text="statusName"
                  outlined
                  label="حالة الطالب"
                  :rules="rules"
                  clearable
                ></v-select>
              </v-col>

              <v-col v-if="editData.status === 5" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-select v-model="editData.exit" :items="exitCauses" color="accent" item-color="accent" outlined label="اسباب الخروج" :rules="rules" clearable></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea v-model="editData.description" color="accent" outlined label="نص الامر الاداري" :rules="rules" clearable></v-textarea>
              </v-col>
            </v-row>

            <v-btn type="submit" color="success" class="primary--text" block large depressed> حفظ الامر الاداري </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-card>
      <v-data-table :items="studentOrders" :headers="headers">
        <template #top>
          <v-toolbar color="accent" class="shadow-1 rounded-1">
            <h4 class="secondary--text">الاوامر الامر الاداري</h4>
            <v-spacer />
            <!-- <ProfileTabsDialogsCreate
              v-if="!row_classess"
              :data="{
                orderTitles,
                yearStudies,
                studentsStatus,
                levels,
                exitCauses,
                studentId,
              }"
              @saved="initialization"
            /> -->
          </v-toolbar>
        </template>

        <template #[`item.imagePath`]="{ item }">
          <img
            :key="item.idAdministrative"
            class="align-end imageUser"
            :src="item.imagePath && item.imagePath != 'none' ? $store.state.apiUrlRegistration + '/' + item.imagePath : require('../../assets/logo.png')"
            height="100px"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            @dblclick="pickImage(item.imagePath)"
          />
        </template>

        <template #[`item.actions`]="{ item, index }">
          <!-- <v-btn icon color="warning" depressed :disabled="row_classess" @click="editStudentOrder(item)">
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn> -->

          <v-btn icon color="error" depressed :disabled="row_classess" @click="deleteOrder(item, index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template #[`item.orderLevel`]="{ item }">
          <span>{{ convertIntToText(item.orderLevel).text }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      studentOrders: [],
      yearStudies: [],
      studentsStatus: [],
      orderTitles: [],

      openCreateDialog: false,
      openEditDialog: false,
      idAdministrative: null,
      studentExitCauses: [],

      editData: {
        title: "",
        number: "",
        year: "",
        level: "",
        status: "",
        exit: "",
        description: "",
        activePicker: null,
        date: null,
        menu: false,
        hideStatus: false,
        hideExit: true,
      },

      rules: [(v) => !!v || "لا يمكن ترك الحقل فارغ"],

      levels: [
        {
          text: "المرحلة الاولى",
          value: 1,
        },
        {
          text: "المرحلة الثانية",
          value: 2,
        },
        {
          text: "المرحلة الثالثة",
          value: 3,
        },
        {
          text: "المرحلة الرابعة",
          value: 4,
        },
        {
          text: "المرحلة الخامسة",
          value: 5,
        },
      ],

      exitCauses: ["لايوجد", "نقل", "ترقين قيد", "ترقين قيد بناءاّ على طلبه", "الغاء قبول", "رسوب بالغياب", "رسوب بالغش", "مزور", "تأجيل سنة"],

      headers: [
        {
          text: "#",
          value: "idAdministrative",
          sortable: false,
          align: "start",
          selected: true,
          width: 50,
        },
        {
          text: "صورة الامر",
          value: "imagePath",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "عنوان الامر",
          value: "title",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "رقم الامر",
          value: "orderNumber",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "سنة الامر",
          value: "year",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "تاريخ الامر",
          value: "orderDateFormat",
          sortable: true,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "مرحلة الامر",
          value: "orderLevel",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "نص الامر",
          value: "orderDescription",
          sortable: false,
          align: "start",
          selected: true,
          width: 500,
        },

        {
          text: "الاجراءات",
          value: "actions",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
      ],
      studentSection: "",
      lightBox: false,
      selectedImage: "",
    };
  },

  computed: {
    row_classess() {
      // const userSection = this.$auth.user.sectionId;
      // const orderSection = this.studentSection;
      // console.log(orderSection);
      // return userSection !== orderSection;
      return false;
    },
  },

  created() {
    this.initialization();
  },

  methods: {
    save(date) {
      this.$refs.editDataMenu.save(date);
    },

    async initialization() {
      let self = this;

      this.$http
        .get(`${this.$store.state.apiUrlRegistration}/studentAdministrativeOrder/${this.studentId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((response) => {
          console.log(response.data);
          self.studentOrders = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("حدث خطأ في البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },
    pickImage(url) {
      let self = this;
      console.log(url);
      let apiUrl = self.$store.state.apiUrlRegistration;
      if (url != "" && url != "none") {
        this.selectedImage = `${apiUrl}/${url}`;
        this.lightBox = true;
      } else {
        this.selectedImage = require("../../assets/logo.png");
        // this.lightBox = true;
      }
    },
    editStudentOrder(item) {
      const userSection = this.$auth.user.sectionId;
      const orderSection = item.user.sectionId;
      this.idAdministrative = item.idAdministrative;

      console.log(item);

      if (userSection === orderSection) {
        this.openEditDialog = true;
        this.editData = {
          ...item,
          title: item.orderTitle.idOrderTitle,
          number: item.orderNumber,
          year: item.orderYear,
          level: item.orderLevel,
          status: item.student.studentStatus.idStudentStatus,
          exit: item.student.ExitCauses.length < 1 ? "" : item.student.ExitCauses[item.student.ExitCauses.length - 1].exitCausesTitle,
          description: item.orderDescription,
          date: item.orderDate,
        };
      } else {
        this.$toast.error("لا تملك الصلاحيات لتعديل هذا الامر الاداري", {
          duration: 5000,
          position: "top-center",
        });
      }
    },

    updateOrder() {
      const data = {
        orderTitleId: this.editData.title,
        orderNumber: this.editData.number,
        orderDescription: this.editData.description,
        orderYear: this.editData.year,
        orderLevel: typeof this.editData.level === "object" ? this.editData.level.value : this.editData.level,
        studentId: this.studentId,
        orderDate: this.editData.date,
        createdBy: this.editData.createdBy,
        studentStatusId: this.editData.status,
      };

      this.$axios
        .put(`administrativeOrder/${this.idAdministrative}`, data)
        .then((result) => {
          this.openEditDialog = false;
          this.$toast.success("تم حفظ المعلومات", {
            duration: 3000,
            position: "top-center",
          });

          if (this.editData.status === 5) {
            const exitinfo = {
              exitCausesTitle: this.exitCausesModel,
              studentId: this.studentId,
              createdBy: this.$auth.user.idUser,
            };

            if (this.studentExitCauses.length < 1) {
              this.$axios
                .post("addExitCauses", exitinfo)
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.$axios
                .put(`exitCauses/${this.studentExitCauses[0].idExitCauses}`, {
                  exitCausesTitle: this.editData.exit,
                  studentId: this.studentId,
                  createdBy: this.$auth.user.idUser,
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }

          this.initialization();
        })
        .catch((error) => {
          console.error(error.response);
        });
    },

    deleteOrder(item, index) {
      let self = this;
      // const userSection = this.$auth.user.sectionId;
      // const orderSection = item.user.sectionId;
      // if (userSection === orderSection) {
      //   if (confirm("هل تريد حذف الامر الاداري ؟")) {
      //     this.$axios.delete(`administrativeOrder/${item.idAdministrative}`).then(() => {
      //       this.$toast.success("تم حذف الامر الاداري", {
      //         duration: 3000,
      //         position: "top-center",
      //       });
      //       this.studentOrders.splice(index, 1);
      //     });
      //   }
      // } else {
      //   this.$toasted.error("لا تملك الصلاحيات لحذف هذا الامر الاداري", {
      //     duration: 5000,
      //     position: "top-center",
      //   });
      // }

      this.$http
        .delete(`${this.$store.state.apiUrlRegistration}/administrativeOrder/${item.idAdministrative}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((response) => {
          console.log(response.data);
          this.$toasted.success("تم حذف البيانات", {
            duration: 2000,
            position: "top-center",
          });
          self.initialization();
        })
        .catch((error) => {
          this.$toasted.error("حدث خطأ في حذف البيانات", {
            duration: 3000,
            position: "top-center",
          });
        });
    },

    convertIntToText(int) {
      let str = {};
      if (int === 1) {
        str = {
          text: "المرحلة الاولى",
          value: 1,
        };
      } else if (int === 2) {
        str = {
          text: "المرحلة الثانية",
          value: 2,
        };
      } else if (int === 3) {
        str = {
          text: "المرحلة الثالثة",
          value: 3,
        };
      } else if (int === 4) {
        str = {
          text: "المرحلة الرابعة",
          value: 4,
        };
      } else if (int === 5) {
        str = {
          text: "المرحلة الخامسة",
          value: 5,
        };
      } else {
        str = {
          text: "متخرج",
          value: 10,
        };
      }

      return str;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-card id="tableWrapper" class="shadow-navbar rounded-1">
      <v-toolbar dark color="primary" class="shadow-navbar rounded-1 mb-0 mt-5">
        <h3>قيود الطلبة</h3>

        <v-spacer></v-spacer>
        <v-tooltip bottom color="secondary" transition="slide-y-transition">
          <template #activator="{ on, attrs }">
            <v-btn icon color="secondary" class="primary--text ml-3" v-bind="attrs" v-on="on" @click="$router.push('/add-student')">
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-btn>
          </template>
          <span class="accent--text">انشاء قيد جديد</span>
        </v-tooltip>

        <div v-if="isStudentSelected && studentSelected.length > 0">
          <v-tooltip bottom color="primary" transition="slide-y-transition">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <TransfererStudent :isLevelUpApi="false" :isGraduationApi="false" :studentSelected="studentSelected" />
              </div>
            </template>
            <span class="secondary--text">تعميم امر اداري</span>
          </v-tooltip>
        </div>

        <div v-if="isStudentSelected && studentSelected.length > 0">
          <v-tooltip bottom color="primary" transition="slide-y-transition">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <TransfererStudent :isLevelUpApi="true" :isGraduationApi="false" :studentSelected="studentSelected" />
              </div>
            </template>
            <span class="secondary--text">ترحيل الطلبة</span>
          </v-tooltip>
        </div>

        <div v-if="isStudentSelected && studentSelected.length > 0">
          <v-tooltip bottom color="primary" transition="slide-y-transition">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <TransfererStudent :isLevelUpApi="false" :isGraduationApi="true" :studentSelected="studentSelected" />
              </div>
            </template>
            <span class="secondary--text">تخريج الطلبة</span>
          </v-tooltip>
        </div>

        <v-tooltip v-if="students.length > 0" bottom color="secondary" transition="slide-y-transition">
          <template #activator="{ on, attrs }">
            <v-btn icon color="secondary" class="primary--text ml-3" v-bind="attrs" v-on="on" @click="tableExport('#studentsTable table', 'students', 'students.xls')">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <span class="primary--text">تصدير الجدول</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        id="studentsTable"
        :headers="headers"
        show-select
        :items="students"
        @dblclick:row="openProfile"
        @item-selected="selectStudent"
        @toggle-select-all="selectStudent"
        v-model="studentSelected"
        :items-per-page="1000"
        item-key="idStudent"
        class="table-content"
        :no-data-text="dataMessage"
      >
        <template v-slot:[`item.index`]="{ index }">
          <p style="padding: 0 3px">{{ index + 1 }}</p>
        </template>
        <template v-slot:[`item.level`]="{ item }">
          <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
        </template>

        <template v-slot:[`item.section`]="{ item }">
          <p style="padding: 0 3px">{{ $store.state.sections.find((sec) => sec.idSection == item.sectionId).sectionName }}</p>
        </template>

        <template v-slot:[`item.studyType`]="{ item }">
          <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
        </template>
      </v-data-table>
    </v-card>
    <a id="dlink" class="d-none" href="">Download</a>
    <EditStudent :showDialog="showDialog" v-if="students.length > 0" />
  </div>
</template>

<script>
import TransfererStudent from "./TransfererStudent.vue";
import EditStudent from "./../EditStudent.vue";

export default {
  components: {
    TransfererStudent,
    EditStudent,
  },
  props: {
    students: Array,
  },
  data() {
    return {
      tableLoading: true,
      studentSelected: [],
      isStudentSelected: false,
      search: "",
      showDialog: false,
      dataMessage: "الرجاء البحث عن طلبة",
      headers: [
        {
          text: "التسلسل",
          sortable: false,
          align: "right",
          value: "index",
          selected: true,
        },
        {
          text: "الرقم الجامعي",
          sortable: false,
          align: "right",
          value: "collegeNumber",
          width: 200,
          selected: true,
        },
        {
          text: "اسم الطالب",
          sortable: true,
          align: "right",
          value: "studentName",
          width: 200,
          selected: true,
        },
        {
          text: "القسم",
          value: "section",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "المرحلة",
          value: "level",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "نوع الدراسة",
          value: "studyType",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "حالة الطالب",
          value: "statusName",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "سنة القبول",
          value: "year",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "الجنس",
          value: "gender",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "الاسم الانجليزي",
          value: "englishName",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "بريد الطالب",
          value: "mail",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "تاريخ الميلاد",
          value: "dob",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الشعبة",
          value: "class",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الجنسية",
          value: "nationality",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "نوع القبول",
          value: "acceptedName",
          width: 200,
          sortable: false,
          selected: false,
        },
      ],
    };
  },
  methods: {
    customTableSearch(value, search, items) {
      return items.studentName.toString().startsWith(search);
    },

    showStudentSelectCheckBox() {
      return this.showSelectCheckBox;
    },

    openProfile(event, item) {
      this.$store.state.selectedStudent = item;
      this.$store.state.showDialog = true;
      // this.$router.push(`/students/profile/${idStudent}`)
    },
    tableExport(table, name, filename) {
      var uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
          });
        };
      if (!table.nodeType) table = document.querySelector(table);
      var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };

      document.getElementById("dlink").href = uri + base64(format(template, ctx));
      document.getElementById("dlink").download = filename;
      document.getElementById("dlink").click();
    },
    selectStudent(item) {
      // select all
      if (item.items && item.value === true) {
        this.studentSelected = item.items;
        this.$store.state.studentSelectedList = item.items;
        this.isStudentSelected = true;
      }
      // select one
      else if (item.items === undefined && item.value === true) {
        this.studentSelected.push(item.item);
        this.$store.state.studentSelectedList.push(item.item);
        this.isStudentSelected = true;
      }
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else if (level == null) {
        return "لايوجد";
      }
    },
    setStudyType: function (type) {
      if (type == 1) {
        return "صباحي";
      } else if (type == 2) {
        return "مسائي";
      } else {
        return "لايوجد";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.visited {
  background: rgba(black, 0.3);

  * {
    color: var(--v-primary-text) !important;
  }
}
</style>

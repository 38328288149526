<template>
  <div>
    <v-form>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-select
            v-model="imageType"
            :items="$store.state.imageTypes"
            item-text="typeName"
            item-value="idImageType"
            item-color="primary"
            outlined
            color="primary"
            label="نوع الصورة"
            @change="isTypeSelected = true"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-file-input v-model="attachment" color="primary" outlined label="اختر الملف..." :disabled="!isTypeSelected" @change="isFileSelected = true"></v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <v-btn depressed color="primary" large :disabled="!isFileSelected" @click="saveFile">
      <span class="secondary--text">حفظ المعلومات</span>
    </v-btn>

    <v-btn depressed color="accent" large class="mr-5 ml-5" @click="$store.state.studentStep = 4">
      <span class="error--text">رجوع</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageType: "",
      selectImageType: [],
      attachment: null,
      isTypeSelected: false,
      isFileSelected: false,
    };
  },

  computed: {
    studentId() {
      return this.$store.state.lastStudentRegistered;
    },
  },

  methods: {
    saveFile() {
      let self = this;
      const data = {
        imagePath: "",
        imageTypeId: this.imageType,
        studentId: self.$store.state.newStudentId,
      };

      const file = new FormData();
      file.append("attachment", this.attachment);

      this.$http.post(`${self.$store.state.apiUrlRegistration}/uploadStudentImageImage`, file, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }).then((path) => {
        data.imagePath = path.data.imagePath;
        this.$http
          .post(`${self.$store.state.apiUrlRegistration}/addStudentImage`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((result) => {
            console.log(result);
            this.$toasted.success("تم حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ في حفظ المعلومات", {
              duration: 3000,
              position: "top-center",
            });
            console.error(error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-card id="tableWrapper" class="shadow-navbar rounded-1">
      <v-toolbar dark color="primary" class="shadow-navbar rounded-1 mb-0 mt-5">
        <h3>الاوامر الادارية</h3>

        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="orders.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab small color="secondary" @click="exportExcel()" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-microsoft-excel </v-icon>
            </v-btn>
          </template>
          <span>تصدير EXCEL</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table :headers="headers" :items="orders" :items-per-page="200" item-key="idAdministrative" class="table-content">
        <template v-slot:[`item.index`]="{ index }">
          <p style="padding: 0 3px">{{ index + 1 }}</p>
        </template>
        <template v-slot:[`item.level`]="{ item }">
          <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
        </template>

        <template v-slot:[`item.orderLevel`]="{ item }">
          <p style="padding: 0 3px">{{ item.orderLevel | setLevel }}</p>
        </template>

        <template v-slot:[`item.section`]="{ item }">
          <p style="padding: 0 3px">{{ $store.state.sections.find((sec) => sec.idSection == item.sectionId).sectionName }}</p>
        </template>

        <template v-slot:[`item.studyType`]="{ item }">
          <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
const Excel = require("exceljs");
export default {
  props: {
    orders: Array,
  },
  data() {
    return {
      selectStudent: false,
      studentSelected: [],

      headers: [
        {
          text: "الرقم الجامعي",
          sortable: true,
          align: "right",
          value: "collegeNumber",
          width: 200,
          selected: true,
        },
        {
          text: "اسم الطالب",
          sortable: true,
          align: "right",
          value: "studentName",
          width: 200,
          selected: true,
        },
        {
          text: "القسم",
          value: "section",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "المرحلة",
          value: "level",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "نوع الدراسة",
          value: "studyType",
          width: 200,
          sortable: false,
          selected: true,
        },
        {
          text: "عنوان الامر",
          value: "title",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "رقم الامر",
          value: "orderNumber",
          sortable: false,
          align: "start",
          selected: true,
          width: 100,
        },
        {
          text: "تاريخ الامر",
          value: "orderDateFormat",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "مرحلة الامر",
          value: "orderLevel",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "سنة الامر",
          value: "year",
          sortable: false,
          align: "start",
          selected: true,
          width: 200,
        },
        {
          text: "نص الامر",
          value: "orderDescription",
          sortable: false,
          align: "start",
          selected: true,
          width: 500,
        },
        {
          text: "حالة الطالب",
          value: "statusName",
          width: 200,
          sortable: false,
          selected: true,
        },

        {
          text: "الاسم الانجليزي",
          value: "englishName",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "بريد الطالب",
          value: "mail",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الجنس",
          value: "gender",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "تاريخ الميلاد",
          value: "dob",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الشعبة",
          value: "class",
          width: 200,
          sortable: false,
          selected: false,
        },
        {
          text: "الجنسية",
          value: "nationality",
          width: 200,
          sortable: false,
          selected: false,
        },
      ],
    };
  },
  methods: {
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("الاوامر الادارية");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 20 },
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "حساب الطالب", key: "userName", width: 20 },
        { header: "رقم الهاتف", key: "studentPhone", width: 50 },
        { header: "الملاحظات", key: "notes", width: 50 },
        { header: "نوع الدراسة", key: "studyType", width: 50 },
        { header: "الحالة المادية", key: "paid", width: 50 },
        { header: "القسم الذي سدد له", key: "sectionName", width: 50 },
        { header: "رقم الوصل", key: "voucherNumber", width: 20 },
      ];
      self.orders.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName,
          collegeNumber: student.collegeNumber,
          sectionName: self.$store.state.sections.find((sec) => sec.idSection == student.sectionId).sectionName,
          acceptedName: student.acceptedName,
          userName: `t_${student.studentCollegeNumber}`,
          studentPhone: student.studentPhone,
          notes: student.formNotes,
          studyType: student.firstStudyType,
          paid: student.amount != null ? "مسدد" : "غير مسدد",
          sectionName: student.sectionName,
          voucherNumber: student.voucherNumber != null ? student.voucherNumber : " ",
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `applicationReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else if (level == null) {
        return "لايوجد";
      } else {
        return "متخرج";
      }
    },
    setStudyType: function (type) {
      if (type == 1) {
        return "صباحي";
      } else if (type == 2) {
        return "مسائي";
      } else {
        return "لايوجد";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
